
import { defineComponent } from "vue";
import Navbar from "@/components/Navbar.vue";
import helpers from "@/helpers/global";
const __default__ = defineComponent({
  name: "App",
  components: {
    Navbar,
  },
  created() {
    this.$store.dispatch("auth/setUserLogin");
  },
  watch: {
    isReady(value) {
      if (value === true) {
        setTimeout(() => {
          document.body.style.visibility = "visible";
        }, 200);
      }
    },
  },
  computed: {
    isReady() {
      return this.$store.getters["isReady"];
    },
    companyColor() {
      const defaultCompanyColor = "#000";
      const user = this.$store.getters["user/user"];
      const userRoute = this.$store.getters["routes/user"];
      if (user && user.color) {
        const check = helpers.isLightColor(user.color);
        if (check) {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.fontColor = "inherrit";
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.iconColor = "invert(1)";
        } else {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.fontColor = "#fff";
        }
        return user.color;
      } else if (userRoute && userRoute.color) {
        const check = helpers.isLightColor(userRoute.color);
        if (check) {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.fontColor = "inherrit";
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.iconColor = "invert(1)";
        } else {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.fontColor = "#fff";
        }
        return userRoute.color;
      } else {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.fontColor = "#fff";
      }
      return defaultCompanyColor;
    },
  },
  data() {
    return {
      fontColor: "",
      iconColor: "",
    };
  },
  mounted() {
    this.setLang();
  },
  methods: {
    setLang() {
      const lang = localStorage.getItem("lang");
      if (lang == "EN") {
        (this as any).$i18n.setLocale("en");
      } else if (lang == "TH") {
        (this as any).$i18n.setLocale("th");
      } else if (lang == "FALSE") {
        (this as any).$i18n.setLocale("false");
      }
      // switch (lang) {
      //   case "EN":
      //     (this as any).$i18n.setLocale("en");
      //     console.log("en");
      //     break;
      //   case "TN":
      //     (this as any).$i18n.setLocale("th");
      //     console.log("th");
      //     break;
      //   case "false":
      //     (this as any).$i18n.setLocale("false");
      //     console.log("false");
      //     break;
      //   default:
      //     (this as any).$i18n.setLocale("en");
      // }
    },
  },
});

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "dc0db35c": (_ctx.companyColor),
  "d81ef330": (_ctx.fontColor),
  "c64e3f04": (_ctx.iconColor)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__