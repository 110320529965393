import {
  getFirestore,
  collection,
  // query,
  // where,
  // doc,
  // setDoc,
  addDoc,
  // orderBy,
  // getDocs,
} from "firebase/firestore";
// import _ from "lodash";

// import asssessmentSectionsData from "./data/assessment_sections.json";

export default {
  namespaced: true,
  state: {
    error: null,
    loading: false,
  },
  mutations: {
    error(state: { error: boolean }, error: boolean): any {
      state.error = error;
    },
    loading(state: { loading: boolean }, loading: boolean): any {
      state.loading = loading;
    },
  },
  getters: {
    error(state: { error: boolean }): any {
      return state.error;
    },
    loading(state: { loading: boolean }): any {
      return state.loading;
    },
  },
  actions: {
    async setup({ dispatch }: { dispatch: any }): Promise<any> {
      console.log("fixtures:setup()");
      // dispatch("valuesAndTraits");
      dispatch("assessmentSections");
    },
    async _setupValues(): Promise<any> {
      const rows = ["Achievement", "Adaptability", "Adventure", "Aesthetics"];
      const options = [];
      for (let i = 0; i < 5; i++) {
        options.push({
          value: Number(i + 1),
          label: String(i + 1),
        });
      }

      const db = getFirestore();

      const valuesAndTraitsId = "xglXaW92ErrLDCNfDAFV";
      const valuesScenarioId = "hv2vqHxkpyRSEPILe6QV";
      const path = `assessment_sections/${valuesAndTraitsId}/scenarios/${valuesScenarioId}/questions`;

      const max = 30;
      for (let i = rows.length; i < max; i++) {
        const name =
          "Topic-" + ("0" + (i + 1)).substring(i + 1 < 10 ? 0 : 1, 3);
        rows.push(name);
      }

      for (const i in rows) {
        const data = {
          name: rows[i],
          title: rows[i],
          order: Number(i) + 1,
          type: "choice",
          options: options,
        };

        const docRef = await addDoc(collection(db, path), data);
        console.log(docRef, "docRef");
      }
    },
    async _setupTraits(): Promise<any> {
      const rows = [
        "Articulate",
        "Authentic",
        "Balanced",
        "Careful",
        "Compassionate",
        "Competent",
      ];
      const options = [];
      for (let i = 0; i < 5; i++) {
        options.push({
          value: Number(i + 1),
          label: String(i + 1),
        });
      }

      const db = getFirestore();

      const valuesAndTraitsId = "xglXaW92ErrLDCNfDAFV";
      const traitsScenarioId = "JVSBnfr6DHOIznAws0PR";
      const path = `assessment_sections/${valuesAndTraitsId}/scenarios/${traitsScenarioId}/questions`;

      const max = 30;
      for (let i = rows.length; i < max; i++) {
        const name =
          "Topic-" + ("0" + (i + 1)).substring(i + 1 < 10 ? 0 : 1, 3);
        rows.push(name);
      }

      for (const i in rows) {
        const data = {
          name: rows[i],
          title: rows[i],
          order: Number(i) + 1,
          type: "choice",
          options: options,
        };

        const docRef = await addDoc(collection(db, path), data);
        console.log(docRef, "docRef");
      }
    },
    async valuesAndTraits({ dispatch }: { dispatch: any }): Promise<any> {
      console.log("valuesAndTraits()");
      dispatch("_setupValues");
      dispatch("_setupTraits");
    },
    /*
    async assessmentSections({ commit }: { commit: any }): Promise<any> {
      console.log("assessmentSections()");
      // const cachedId = "assessment_sections";
      // await dispatch("caches/load", cachedId, { root: true });
      // const data = rootState.caches.assessmentSections;
      // console.log(asssessmentSectionsData, "asssessmentSectionsData");
      commit("loading", true);
      const db = getFirestore();
      const data: any = asssessmentSectionsData;
      // console.log(data, "data");
      for (const sectionName in data) {
        const sectionData = data[sectionName];
        await setDoc(
          doc(db, "assessment_sections", sectionData.id),
          {
            name: sectionData.name,
            order: sectionData.order,
            title: sectionData.title,
          },
          { merge: true }
        );
        const scenarios = sectionData.scenarios;
        for (const i in scenarios) {
          const scenario = scenarios[i];
          const scenarioPath = `assessment_sections/${sectionData.id}/scenarios`;
          const scenarioData: any = {
            name: scenario.name,
            order: scenario.order,
            title: scenario.title,
            description: scenario.description,
          };
          if (scenario.admin_description)
            scenarioData.admin_description = scenario.admin_description;
          if (scenario.sum_description)
            scenarioData.sum_description = scenario.sum_description;
          await setDoc(doc(db, scenarioPath, scenario.id), scenarioData, {
            merge: true,
          });
          const questions = scenario.questions;
          for (const j in questions) {
            const question = questions[j];
            const questionPath = `assessment_sections/${sectionData.id}/scenarios/${scenario.id}/questions`;

            let questionData = {};
            switch (sectionName) {
              case "values_and_traits":
                questionData = _.pick(question, [
                  "name",
                  "title",
                  "type",
                  "order",
                  "options",
                  "disabled",
                ]);
                break;
              case "oaic_archetypes":
                questionData = _.pick(question, [
                  "name",
                  "title",
                  "admin_title",
                  "type",
                  "order",
                  "profile",
                ]);
                break;
              case "acceptable_behaviors":
                questionData = _.pick(question, [
                  "name",
                  "title",
                  "type",
                  "order",
                  "options",
                ]);
                break;
              case "work_style_identifier":
                questionData = _.pick(question, [
                  "name",
                  "title",
                  "order",
                  "description",
                  "options",
                ]);
                break;
            }

            await setDoc(doc(db, questionPath, question.id), questionData, {
              merge: true,
            });
          }
        }
      }
      commit("loading", false);
    },
    //*/
  },
};
