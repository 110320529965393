import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

// Libary
import ElementPlus from "element-plus";
import i18n from "./plugins/vue-i18n";
import { pageTitle } from "vue-page-title";
import MasonryWall from "@yeger/vue-masonry-wall";

// style
import "element-plus/dist/index.css";
import "@/assets/style/components.scss";
import "@/assets/style/style.scss";

// Firebase
import { initializeApp } from "firebase/app";
const firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.VUE_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID,
};

// Initialize Firebase
initializeApp(firebaseConfig);

const app = createApp(App);

app.use(i18n);
app.use(router);
app.use(pageTitle({ router, mixin: true }));
app.use(store);
app.use(ElementPlus);
app.use(MasonryWall);
app.mount("#app");
