import { getFirestore, doc, getDoc, setDoc } from "firebase/firestore";
import helpers from "@/helpers/global";
import _ from "lodash";

// Collection Name
const CN = "coupons";

export default {
  namespaced: true,
  state: {
    error: null,
    loading: false,
    data: null,
  },
  mutations: {
    error(state: { error: boolean }, error: boolean): any {
      state.error = error;
    },
    loading(state: { loading: boolean }, loading: boolean): any {
      state.loading = loading;
    },
    data(state: { data: any }, data: { credit?: number }): any {
      state.data = data;
    },
  },
  getters: {
    error(state: { error: boolean }): any {
      return state.error;
    },
    loading(state: { loading: boolean }): any {
      return state.loading;
    },
    data(state: { data: any }): any {
      return state.data;
    },
  },
  actions: {
    async load({ commit }: { commit: any }, email: string): Promise<any> {
      commit("loading", true);
      console.log(email, "email");
      commit("loading", false);
    },
    async redeem(
      {
        commit,
        dispatch,
        rootState,
      }: { commit: any; dispatch: any; rootState: any },
      couponCode: string
    ): Promise<any> {
      commit("loading", true);

      if (!couponCode) {
        commit("error", helpers.t("coupon.error_messages.message_1"));
        commit("loading", false);
        return;
      }

      const _id = String(couponCode).toUpperCase();

      const fs = getFirestore();
      const docRef = doc(fs, CN, _id);
      const docSnap = await getDoc(docRef);
      if (!docSnap.exists()) {
        commit("error", helpers.t("coupon.error_messages.message_2"));
        commit("loading", false);
        return;
      }

      const data = docSnap.data();
      const couponId = docSnap.id;

      commit("data", data);

      // Expired?
      if (data.expiry_date) {
        let now = helpers.now();
        let expired = data.expiry_date + " 23:00:00";

        now = helpers.isSafari() ? now.replace(/-/g, "/") : now;
        expired = helpers.isSafari() ? expired.replace(/-/g, "/") : expired;

        const nowDate = new Date(now);
        const expiryDate = new Date(expired);
        const canUse = expiryDate.getTime() >= nowDate.getTime();

        if (!canUse) {
          commit("error", helpers.t("coupon.error_messages.message_3"));
          commit("loading", false);
          return;
        }
      }

      // Usage Limit
      if (!data.usage_limit) {
        commit("error", helpers.t("coupon.error_messages.message_4"));
        commit("loading", false);
        return;
      }

      const user = rootState.auth.user;
      const trasctionId = couponId + "-" + helpers.emailKey(user.email);

      // You already used it
      const docTransRef = doc(fs, "coupon_transactions", trasctionId);
      const docTransSnap = await getDoc(docTransRef);
      if (docTransSnap.exists()) {
        commit("error", helpers.t("coupon.error_messages.message_5"));
        commit("loading", false);
        return;
      }

      // Create Coupon history usage
      const usageDataDetails = {
        email: user.email,
        creator: data.creator,
        name: data.name,
        code: couponCode,
        credit: data.credit,
        couponData: _.cloneDeep(data),
        created_at: helpers.now(),
      };
      // await addDoc(collection(fs, "coupon_transactions"), usageDataDetails);
      await setDoc(
        doc(fs, "coupon_transactions", trasctionId),
        usageDataDetails
      );

      // Update Coupon usage limit
      await setDoc(
        doc(fs, CN, _id),
        {
          usage_limit: parseInt(data.usage_limit) - 1,
          updated_at: helpers.now(),
        },
        { merge: true }
      );

      // Finally, Update user quota
      // TODO: cloud function
      const quotaData = rootState.quota.data;
      const credit = parseInt(data.credit);
      const formDetails = {
        advanced_quota_total: parseInt(quotaData.advanced_quota_total) + credit,
        quota_total: parseInt(quotaData.quota_total) + credit,
        quota_remaining: parseInt(quotaData.quota_remaining) + credit,
        updated_at: helpers.now(),
      };
      // console.log(formDetails, "formDetails");

      await dispatch(
        "quota/upsertQuota",
        { email: user.email, formDetails },
        { root: true }
      );

      // Reative variable at top menu
      const newQuotaData = _.merge(quotaData, formDetails);
      commit("quota/data", newQuotaData, { root: true });

      commit("error", "");
      commit("loading", false);
    },
  },
};
