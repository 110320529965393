import {
  getFirestore,
  collection,
  query as $query,
  getDocs,
  where,
  orderBy,
  limit,
} from "firebase/firestore";
import _ from "lodash";

export default {
  namespaced: true,

  state: {
    itemscandidate: [],
    loading: false,
    searching: "",
    itemsAssessment: [],
  },
  mutations: {
    itemsAssessment(
      state: { itemsAssessment: any },
      itemsAssessment: { _id: string }
    ): any {
      state.itemsAssessment = itemsAssessment;
    },
    itemscandidate(
      state: { itemscandidate: any },
      itemscandidate: { id: string }
    ): any {
      state.itemscandidate = itemscandidate;
    },
    loading(state: { loading: boolean }, loading: boolean): any {
      state.loading = loading;
    },
  },
  getters: {
    itemsAssessment(state: { itemsAssessment: any[] }): any {
      return state.itemsAssessment;
    },
    itemscandidate(state: { itemscandidate: any[] }): any {
      return state.itemscandidate;
    },
    loading(state: { loading: boolean }): any {
      return state.loading;
    },
  },
  actions: {
    async getList({
      commit,
      rootState,
    }: {
      commit: any;
      dispatch: any;
      rootState: any;
    }): Promise<any> {
      commit("loading", true);
      const $db = getFirestore();

      const email = rootState.auth.user.email;
      const uuid = "assessment_template#" + email;
      const qu = $query(
        collection($db, "assessment_templates"),
        where("uuid", "==", uuid),
        orderBy("form_name", "desc")
      );
      const querySnapshotAssessment = await getDocs(qu);
      const itemsAssessment: any[] = [];
      querySnapshotAssessment.forEach(async (doc: any) => {
        //console.log(doc.data(), doc.id);
        const data = doc.data();
        const itemoption = {
          value: doc.id,
          label: data.form_name,
        };
        itemsAssessment.push(itemoption);
        commit("itemsAssessment", itemsAssessment);
      });
      const q = $query(
        collection($db, "assessments"),
        where("creator", "==", email),
        where("deleted", "==", false),
        // @fix prevent overload data
        // @todo pagination
        limit(500)
      );
      const querySnapshot = await getDocs(q);
      let itemscandidate: any[] = [];

      querySnapshot.forEach(async (doc: any) => {
        let score = "0";
        const data = doc.data();
        const joined_at_date = data.joined_at || "";
        if (data.status == "COMPLETED") {
          score = data.summaries ? data.summaries.overall : "0";
        }
        const candidateName =
          (data.firstname + " " + data.lastname).trim() ||
          data.displayName ||
          data.email;
        const item = {
          candidateName: candidateName,
          id: doc.id,
          _id: doc.id,
          joined: joined_at_date,
          score: parseInt(score),
          status: data.status,
          rating: data.rating,
          email: data.email,
          formId: data.form_id,
          formName: data.form_name,
          firstname: data.firstname,
          lastname: data.lastname,
          joined_at: joined_at_date,
          display_name: candidateName,
          action_url: data.action_url,
          data: data,
        };
        itemscandidate.push(item);
      });
      itemscandidate = _.sortBy(itemscandidate, "score").reverse();
      commit("itemscandidate", itemscandidate);
      commit("loading", false);
    },
  },
};
