const th = {
  lang_code: "th",
  f_thai: "ไทย",
  thai: "ไทย",
  f_english: "english",
  English: "English",
  test: "ทดสอบ",
  main: {
    dot: "",
    Values_and_Traits: "ค่านิยม และลักษณะนิสัย",
    Culture_Profile: "รูปแบบวัฒนธรรมองค์กร",
    Acceptable_Behaviors: "พฤติกรรมที่ยอมรับได้",
    Work_Style_Identifier: "ค้นหาสไตล์การทำงาน",
    Human_Skills: "ทักษะเรื่องคน",
  },
  placeholder: {
    full_name: "ชื่อเต็ม",
    email: "อีเมล",
    email_email_com: "email@email.com",
    Work_email: "อีเมลที่ทำงาน",
    First_name: "ชื่อ",
    Last_name: "นามสกุล",
    Company_name: "ชื่อบริษัท",
    What_is_your: "ตำแหน่งงานของคุณ",
    The_number_of_hires: "จำนวนการรับสมัครพนักงานต่อปี (โดยประมาณ)",
    What_level_are_you: "การสร้างวัฒนธรรมองค์กรของคุณอยู่ในระดับใด",
    Coupon_code: "โค้ดคูปอง",
    Email_Address_start: "อีเมล*",
    Search_by_name_or: "ค้นหาจากชื่อหรืออีเมล",
    Assessment: "แบบทดสอบ",
    Your_rating: "ระดับดาว",
    Select: "เลือก",
    Search: "ค้นหา",
    Name_your_assessment: "ตั้งชื่อแบบทดสอบของคุณ",
    What_is_this_assessment_for: "แบบทดสอบนี้มีเพื่ออะไร",
    Number_of_employees: "จำนวนพนักงาน",
  },
  btn: {
    Continue: "ดำเนินการต่อ",
    ADD: "เพิ่ม",
    Next: "ต่อไป",
    Skip: "ข้าม",
    Save: "บันทึก",
    Edit: "แก้ไข",
    Clone: "ทำซ้ำ",
    Delete: "ลบ",
    Invite: "เชิญ",
    Quick_Invite: "เชิญด่วน",
    Get_Started: "เริ่ม",
    Close: "ปิด",
    Previous: "ย้อนกลับ",
    Finish: "เสร็จ",
    Submit: "ส่ง",
    Remove: "ลบ",
    Do_it_later: "ทำในภายหลัง",
    Copy: "คัดลอก",
    Send: "ส่ง",
    Re_send_Invit: "ส่งคำเชิญอีกครั้ง",
    Clear_Results: "ล้างผลลัพธ์",
    Cancel: "ยกเลิก",
  },
  optional: {
    Hiring_candidates: "ผู้สมัครงาน",
    New_Hires: "พนักงานใหม่",
    Current_team_members: "สมาชิกทีมปัจจุบัน",
    Invited: "ส่งคำเชิญแล้ว",
    Started: "เริ่มต้น",
    Incompleted: "ยังไม่สำเร็จ",
    Completed: "สำเร็จ",
  },
  login: {
    Please_confirm_who_you: "ยืนยันข้อมูลของคุณ",
    I_have_read_and: "ฉันได้อ่านและยอมรับ ",
    terms_of_use: "เงื่อนไขการใช้บริการ",
    Send_me_free_stuff:
      "ส่งของกำนัลและอีเมลเกี่ยวกับ Happily เมื่อมีโปรโมชั่นหรือข้อมูลใหม่ๆ ที่น่าสนใจ",
  },
  timeout: {
    No_time_remaining: "หมดเวลา",
    The_allocated_time_for_this_section:
      "เวลาสำหรับเซสชันนี้ได้หมดลงแล้ว โปรดดำเนินการไปขั้นตอนถัดไป",
    Session_Time_Out: "เซสชันหมดเวลา",
    Please_proceed_to_the_next_section:
      "โปรดดำเนินการไปที่ส่วนถัดไปของแบบทดสอบ เพื่อทำการส่งแบบทดสอบ มิเช่นนั้นคำตอบของคุณอาจสูญหายได้ และอาจต้องทำแบบทดสอบอีกครั้ง",
  },
  pincode: {
    Enter_your_PIN_code: "ใส่รหัส PIN-code ที่ส่งไปยัง",
    Sign_in_using_a: "ลงชื่อเข้าใช้ด้วยบัญชีอื่น",
  },
  createAccount: {
    Get_started_with_your: "เริ่มต้นสร้างแบบทดสอบฟรี",
    Create_my_free_account: "สร้างบัญชีฟรี",
    Already_have_a: "มีบัญชีแล้วใช่ไหม?",
    Log_in_to: "เข้าสู่บัญชีของคุณ",
    Log_in: "เข้าสู่ระบบ",
    Sign_up: "สร้างบัญชีใหม่",
    Do_not_have: "ยังไม่มีบัญชีใช่ไหม?",
    OR: "หรือ",
    Continue_with_Google: "ดำเนินการต่อด้วยบัญชี Google",
    Continue_with_Microsoft: "ดำเนินการต่อด้วยบัญชี Microsoft",
    By_signing_up: "ในการสมัครนี้ ฉันได้อ่าน",
    Terms_and_Conditions: "ข้อกำหนดและเงื่อนไข",
    Tell_us_a_bit: "บอกเราซักนิด เกี่ยวกับคุณและบริษัทของคุณ",
    Give_your_assessments: "ใส่สไตล์บริษัทของคุณให้กับแบบทดสอบ",
    // -
    Upload_Logo: "อัปโหลดโลโก้",
    Finish_sign_up: "ลงทะเบียนให้สำเร็จ",
    more_than: "มากกว่า",
  },
  myAccount: {
    Log_out: "ออกจากระบบ",
    My_Account: "บัญชีของฉัน",
    My_Profile: "โปรไฟล์ของฉัน",
    My_Company: "บริษัทของฉัน",
    Plan_and_Billing: "แพ็กเกจ & ค่าบริการ",
    Refer_a_friend: "แนะนำให้เพื่อนใช้",
    First_Name: "ชื่อ",
    Last_Name: "นามสกุล",
    Language: "ภาษา",
    Company_Name: "ชื่อบริษัท",
    Logo: "โลโก้",
    Upload_Logo: "อัปโหลดโลโก้",
    Brand_Color: "สีของแบรนด์",
    Unlimited: "Unlimited",
    __remaining: "เหลือ",
    credit: "เครดิต",
    credits: "เครดิต",
    remaining: "",
    this_month: "สำหรับเดือนนี้",
    Add_Credit: "เพิ่มเครดิต",
    refer_a_friend: {
      Special_offer: "ดีลพิเศษ",
      Refer_a_friend_and: "แนะนำเพื่อนเพื่อรับเครดิตฟรี",
      When_a_friend_signs:
        "หากเพื่อนสร้างบัญชีกับ MyCulture และสร้างแบบทดสอบ คุณจะได้รับ 10 เครดิตฟรี",
      Your_unique_referral_link: "ลิงก์เฉพาะคุณ สำหรับแนะนำเพื่อน",
      Email_your_invite: "อีเมลเพื่อเชิญเพื่อน",
      we_do_not_recognise: "ขอโทษนะ เราไม่พบอีเมลนี้",
      Successful_Referrals: "การแนะนำที่สำเร็จ",
    },
    planAndBill: {
      Your_current_plan: "แพ็กเกจปัจุบันของคุณ",
      Free_Plan: "แพ็กเกจฟรี",
      Unlimited_credits: "เครดิตแบบไม่จำกัด",
      You_are_used: "คุณใช้ไปแล้ว",
      out_of: "จาก",
      credits: "เครดิต",
      this_mounth: "ในเดือนนี้",
      credits_remaining: "เครดิตคงเหลือ",
      A_credit_gets_used: "เครดิตจะถูกใช้เมื่อผู้ทำแบบทดสอบได้ทำแบบทดสอบสำเร็จ",
      // ---
      You_must_have_available: "คุณต้องมีเครดิตเพียงพอเพื่อที่จะส่งคำเชิญ",
      // ---
      All_plans_have_access_to_the_same_benefit:
        "ทุกแพ็คเกจสามารถเข้าถึงสิทธิประโยชน์เหล่านี้ได้เหมือนกัน",
      Unlimited_assessment_variations: "แบบการทดสอบไม่จำกัด",
      Access_to_all_available: "การเข้าถึงทุกหมวดหมู่ (อัปเดตอยู่เสมอ)",
      // ---
      Group_analytics: "การวิเคราะห์ผลแบบกลุ่ม",
      Bulk_invites: "การส่งคำเชิญแบบกลุ่ม",
      Custom_branding: "การกำหนดอัตลักษณ์องค์กรเอง",
      Email_Support: "การสนับสนุนดูแลทางอีเมล",
      Continue: "ต่อไป",
      Downgrade_to: "ลดเกรดสู่",
      Upgrade_to: "อัปเกรดสู่",
      Pay_as_you_go: "แพ็กเกจ Pay as you go",
      For_businesses_that_occasionally:
        "สำหรับธุรกิจที่ต้องการใช้บางโอกาส จ่ายเมื่อใช้งาน สำหรับธุรกิจที่ต้องการใช้บางโอกาส และต้องการความหยืดหยุ่น",
      Pay_one_per_credit: "จ่าย 1$ ต่อเครดิต",
      Buy_credit: "ซื้อเครดิต",
      Cancel_Subscription: "ยกเลิกการเป็นสมาชิก",
      Minimum_purchase_10_credits: "ซื้อเครดิตขั้นต่ำ 10 เครดิต",
      Manage_your_plan: "จัดการแพ็คเกจของคุณ",
      Annual_Subscriptionogo: "สมาชิกรายปี",
      Monthly_Subscription: "แพ็กเกจ Monthly Subscription",
      For_businesses_wiht_high:
        "สมัครสมาชิกแบบรายเดือน สำหรับธุรกิจที่มีการจ้างงานสูง",
      _49_per_month: "จ่าย $49 ต่อเดือน",
      for_unlimited_credits: "เพื่อรับเครดิตแบบไม่จำกัด",
      Subscribe: "สมัครสมาชิก",
      Change_to_Annual: "เปลี่ยนเป็นรายปี",
      Change_to_Monthly: "เปลี่ยนเป็นรายเดือน",
      Select_the_annual_plan: "เลือกแพ็คเกจรายปีเพื่อรับส่วนลด 25%",
      PAY_AS_YOU_GO: "จ่าย เมื่อ ใช้งาน",
      Available_credits_allow_you:
        "คุณสามารถใช้เครดิต เพื่อส่งคำเชิญให้ทำแบบทดสอบ และเปิดใช้งานลิงก์คำเชิญของคุณ เครดิตจะถูกหักเมื่อมีคนทำแบบทดสอบสำเร็จ",
      Buy_10_for_S10: "ซื้อ 10 จ่าย $10",
      Buy_20_for_S18: "ซื้อ 20 จ่าย $18 (ส่วนลด 10%)",
      Buy_30_for_S24: "ซื้อ 30 จ่าย $24 (ส่วนลด 20%)",
      Buy_40_for_S28: "ซื้อ 40 จ่าย $28 (ส่วนลด 30%)",
      Redeem: "แลกสิทธิ์",
      SUBSCRIPTION: "การสมัครสมาชิก",
      Access_unlimited_credits_during:
        "คุณจะได้รับเครดิตแบบไม่จำกัดในระหว่างการเป็นสมาชิก และสามารถยกเลิกได้ทุกเมื่อ เพื่อทำให้แพ็คเกจของคุณไม่ถูกต่อ",
      Monthly_for_S49: "แบบรายเดือน เพียง $49",
      Billed_today_and_on: "ชำระค่าบริการวันนี้ และทุกวันที่ 6 ของเดือน",
      Annual_for_S441: "แบบรายปี เพียง $441 (ส่วนลด 25%)",
      Billed_today_and_next: "ชำระค่าบริการวันนี้ และทุกวันที่ 6 ของเดือน",
      Coupon: "คูปอง",
      Redeem_Free_Credits: "แลกเครดิตฟรี",
      Enter_your_coupon:
        "ใส่โค้ดคูปองที่นี่ หากต้องการฟรีเครดิต ติดต่อเรามาได้เลยที่ ",
      sales_happily_ai: "sales@happily.ai",
      Coupon_Code: "โค้ดคูปอง",
      We_re_sorry: "ขอโทษด้วย โค้ดนี้ไม่สามารถใช้งานได้",
      credits_redeemed_1: "ได้รับ",
      credits_redeemed_2: "เครดิต",
      You_have_successfully: "คุณใช้คูปองโค้ดแลกเครดิตสำเร็จแล้ว",
      // --
      Failed_redeem: "แลกรางวัลไม่สำเร็จ",
    },
    paymentInteruptPopup: {
      title: "การชำระเงินเร็ว ๆ นี้",
      body: "ขอขอบคุณที่สนใจซื้อเครดิต กำลังจะมาในเร็วๆ นี้ ในระหว่างนี้แนะนำเพื่อนเพื่อรับ +10 เครดิตฟรี",
      confirmButtonText: "แนะนำให้เพื่อนใช้",
      cancelButtonText: "ยกเลิก",
    },
  },
  fourZeroFour: {
    Looks_like_something: "ดูเหมือนมีบางอย่างผิดพลาด",
    the_page_or: "ไม่พบหน้าเว็บ หรือลิงค์ที่คุณกำลังค้นหา",
    Our_Website: "เว็บไซต์ของเรา",
  },
  assessments: {
    My_Core_Values: "ค่านิยมของฉัน",
    My_assessments: "แบบทดสอบของฉัน",
    My_respondents: "ผลลัพธ์แบบทดสอบ",
    Create_new_assessment: "สร้างแบบทดสอบใหม่",
    Get_started: "เริ่มต้น",
    by_creating_a_new: "สร้างแบบทดสอบ",
  },
  assessmentsDetails: {
    sections: "ส่วน",
    INVITE_CANDIDATES: "เชิญผู้ทำแบบทดสอบ",
    By_public_link: "ทางลิงค์สาธารณะ",
    By_Email: "ทางอีเมล",
    Invite_in_Bulk: "เชิญแบบกลุ่ม",
    INVITE_MULTIPLE_CANDIDATES: "เชิญแบบกลุ่ม",
    by_Email: "ทางอีเมล",
    emails_detected: " อีเมลที่ถูกเลือก",
    Comma_separate_multiple: "ใช้เครื่องหมาย , เพื่อแยกแต่ละอีเมล",
    Personalized_Bulk_Invites: "ปรับแต่งและส่งแบบกลุ่ม",
    PERSONALIZED_BULK_INVITES: "ปรับแต่งและส่งแบบกลุ่ม",
    Email_Subject: "หัวข้ออีเมล",
    Email_Body: "เนื้อหา",
    Email_Sender: "ผู้ส่ง",
    Recipient_s: "ผู้รับ",
    Tip_Paste_contact: "เคล็ดลับ: วางข้อมูลผู้ติดต่อจากสเปรดชีต",
    First_Name: "ชื่อ",
    Last_Name: "นามสกุล",
    Email: "อีเมล",
    Send_Test_Email: "ส่งอีเมลทดสอบ",
    Send_Invite: "ส่งคำเชิญ",
    The_email_will_include: "อีเมลนี้จะรวมส่วนหัวและส่วนท้ายอีเมล",
  },
  create: {
    Create_new_assessment: "สร้างแบบทดสอบใหม่",
    Untitled_assessment: "แบบทดสอบไม่มีชื่อ",
    Next_Step: "ขั้นตอนถัดไป",
    Name_Assessment: "ใส่ชื่อแบบทดสอบ",
    Select_Sections: "เลือกหมวด",
    Your_Culture_Model: "ต้นแบบวัฒธรรมองค์กรของคุณ",
    Publish: "เผยแพร่",
    Review_and_Publish: "ตรวจสอบ และเผยแพร่",
    Details: "รายละเอียด",
    Selected: "ถูกเลือกแล้ว",
    Select: "เลือก",
    Values_and_Traits: "ค่านิยม และลักษณะนิสัย",
    Values_and_traits_influence:
      "ค่านิยม และลักษณะนิสัยนั้นส่งผลต่อพฤติกรรม เมื่อคนในทีมมีค่านิยมที่ตรงกัน การทำงานร่วมกันก็จะง่าย และเป็นธรรมชาติ และเมื่อเรามีลักษณะนิสัยอันเป็นที่ต้องการสอดคล้องกันด้วยนั้น เราก็จะสามารถปฎิบัติตามค่านิยมต่างๆในการทำงานได้",
    Culture_Profile: "รูปแบบวัฒนธรรมองค์กร",
    Organizational_culture_is_driven:
      "วัฒนธรรมองค์กรนั้นถูกขับเคลื่อนด้วย การแข่งขันทางค่านิยม (Competing Values) 4 แบบด้วยกัน ลองเลือกรูปแบบวัฒนธรรมที่ตรงกับองค์กรของคุณ จาก 4C ดังนี้",
    Clan_Create_Collaborate_and:
      "การร่วมมือกัน (Collaborate), การลงมือทำ (Create), การแข่งขัน (Compete) และการควบคุม (Control)",
    across_six_dimensions_of: "ใน 6 มิติการทำงาน",
    Determine_culture_profile: "กำหนดรูปแบบวัฒธรรมที่เข้ากับองค์กรของคุณ",
    Determine_values_traits:
      "กำหนดค่านิยม และลักษณะนิสัยที่เข้ากับองค์กรของคุณ",
    Behaviors_are_considered_toxic:
      "พฤติกรรมจะกลายเป็นพิษ (Toxic Behaviors) ก็ต่อเมื่อมันไม่ตรงกับความคาดหวัง เพราะฉนั้นโปรดทำความเข้าใจว่าพฤติกรรมใดยอมรับได้ ไม่น่าส่งเสริม หรือยอมรับไม่ได้อย่างเด็ดขาด และดูว่ามีสิ่งใดที่ไม่ตรงกัน",
    Determine_expected_behaviors_and:
      "กำหนดพฤติกรรมที่องค์กรคาดหวัง และผลที่ตามมา",
    Acceptable_Behaviors: "พฤติกรรมที่ยอมรับได้",
    Work_Style_Identifier: "ค้นหาสไตล์การทำงาน",
    Identify_a_candidate_work_style:
      "ค้นหาสไตล์การทำงานของผู้ทำแบบทดสอบจาก แนวคิด 3P",
    Product_Process_or_People:
      "ผลลัพธ์ (Product), ขั้นตอน (Process), ผู้คน, (People)",
    Understanding_the_inclination_for:
      "การเข้าใจแนวโน้มในการทำงานของแต่ละสไตล์นั้น จะช่วยให้เราเห็นภาพมากขึ้นว่าแต่ละคนมีวิธีการทำงานให้สำเร็จ หรือจัดลำดับความสำคัญของงานอย่างไร",
    Setup_your_company_values:
      "กำหนดค่านิยม และลักษณะนิสัยขององค์กรคุณ เพื่อหาสิ่งที่ทำให้องค์กรของคุณแตกต่างจากที่อื่น",
    Setup_your_culture_profile:
      "กำหนดรูปแบบวัฒธรรมองค์กรของคุณ ด้วยการแบ่งว่าองค์กรของคุณมีค่านิยมอยู่ในสัดส่วนใดบ้างตามกรอบแนวคิด การแข่งขันทางค่านิยม (Competing Values) ใน 6 มิติด้านการทำงาน",
    Setup_your_acceptable_behaviors:
      "กำหนดพฤติกรรมที่ยอมรับได้ ด้วยการตัดสินว่าอะไรที่ยอมรับได้ และไม่ได้ จากตัวอย่างสถาการณ์การทำงาน",
    No_set_up_required: "ส่วนนี้ไม่ต้องกำหนด เนื่องจากเราประเมินจากแนวคิด 3P",
    // ---
    Communication_Styles: "สไตล์การสื่อสาร",
    Ready: "พร้อม",
    Set_up: "ตั้งค่า",
    SECTIONS: "หมวด",
    DURATION: "ระยะเวลา",
    Languages_required_to_support: "ภาษาที่ต้องการ",
    Advanced_Options: "ตัวเลือกขั้นสูง",
    Additional_configurations_to: "ตั้งค่าเพิ่มเติมสำหรับแบบทดสอบนี้",
    // ---
    Extra_Time: "เพิ่มเวลา",
    min: "นาที",
    Sharing_Results: "แชร์ผลลัพธ์แบบทดสอบ",
    Auto_send_assessment: "ส่งผลลัพธ์ให้ผู้ทำแบบทดสอบโดยอัตโนมัติ",
    Open_ended_Feedback: "ข้อเสนอแนะ",
    Allow_respondents_to:
      "อนุญาตให้ผู้ทำแบบทดสอบแบ่งปันข้อเสนอแนะในตอนท้ายของแต่ละหมวด",
    Human_Skills: "ทักษะเรื่องคน",
    Human_skills_soft_skills:
      "ทักษะเรื่องคน (Soft skills) - การสื่อสารทางการเขียน ความเข้าอกเข้าใจ การตระหนักรู้ในสถานการณ์ และการคิดวิเคราะห์ เป็นสิ่งสำคัญที่จะทำให้การสร้างความสัมพันธ์ประสบความสำเร็จ และทำให้การให้เหตุผลและการตัดสินใจมีความน่าเชื่อถือ",
    Evaluate_human_skills: "ประเมินทักษะเรื่องคน",
    No_set_up_required_to_assess_written:
      "ไม่จำเป็นต้องตั้งค่าสำหรับ การสื่อสารทางการเขียน ความเข้าอกเข้าใจ การตระหนักรู้ในสถานการณ์ และการคิดวิเคราะห์",
    Your_Core_Values: "ค่านิยมของคุณ",
    Define_your_organizational:
      "กำหนดค่านิยมองค์กร เพื่อเป็นตัวกำหนดรูปแบบวัฒนธรรมของคุณ โดยแต่ละส่วนจะถูกสร้างและประเมินจากค่านิยมของคุณ",
    Quick_Select_or_Add: "เลือกจากคลัง หรือเพิ่มค่านิยมใหม่",
    Start_AI_Assisted_Setup: "ตั้งค่าด้วย AI-Assisted",
    Proceed_with_Manual_Setup: "ตั้งค่าแบบกำหนดเอง",
    Pending_Review: "รอการตรวจสอบ",
    Generate: "Generating..",
    Redirect_respondents_to: "Redirect respondents to this URL on completion",
  },
  setup: {
    Setup_Complete: "การกำหนดค่าเสร็จสมบูรณ์",
    Thank_you_for_setting: "ขอบคุณสำหรับการกำหนดค่าของ",
    You_can_now_close_this_window: "ปิดหน้าต่างนี้ได้",
    _Use_the_same_setup: "- ใช้การตั้งค่าจากแบบทดสอบเดิม -",
    valueAndTraits: {
      Setup_your_Values_and_Traits: "กำหนดค่านิยม และลักษณะนิสัย",
      Not_at_all_important: "ไม่สำคัญเลย",
      Very_important: "สำคัญมาก",
      Your_company_core_values:
        "ค่านิยมองค์กร เป็นเอกลักษณ์เฉพาะขององค์กรที่สามารถสร้างความได้เปรียบทางการแข่งขันให้กับคุณได้ ส่วนลักษณะนิสัย (Traits) เป็นสิ่งที่ทำให้คนในองค์กรสามารถประพฤติ และปฏิบัติได้ตามชุดค่านิยมที่องค์กรมี",
      In_the_next_steps_do:
        "ขั้นตอนถัดไป โปรดเลือกค่านิยม และลักษณะนิสัยที่มีความสำคัญต่อองค์กรของคุณที่สุด",
      Defining_your_values: "กำหนดค่านิยมของคุณ",
      Select_and_manage_value:
        "เลือก และจัดการชุดค่านิยมที่คุณต้องการให้มีอยู่ในแบบทดสอบ",
      You_can_add_values: "คุณสามารถเพิ่มค่านิยมได้เองด้วย",
      Values_Library: "คลังค่านิยม",
      Do_not_include: "รายการนำออก",
      Add_Value: "เพิ่มค่านิยม",
      Add_a_value: "เพิ่มค่านิยม",
      Add_a_value_to_the:
        "เพิ่มค่านิยมในคลัง โปรดตรวจสอบว่ามันไม่ซ้ำ หรือใกล้เคียงเกินไปกับรายการที่มีอยู่แล้ว",
      New_Value_English: "ค่านิยมใหม่ (ภาษาอังกฤษ)",
      New_Value_Thai: "ค่านิยมใหม่ (ภาษาไทย)",
      Enter_value_here: "ใส่ค่านิยมที่นี่",
      Identifying_key_traits: "กำหนดลักษณะนิสัยสำคัญ",
      Select_and_manage_traits:
        "เลือก และจัดการชุดลักษณะนิสัยที่คุณต้องการให้มีอยู่ในแบบทดสอบ",
      You_can_add_traits: "คุณสามารถเพิ่มลักษณะนิสัยได้เองด้วย",
      Traits_Library: "คลังลักษณะนิสัย",
      Add_traits: "เพิ่มลักษณะนิสัย",
      Add_a_trait: "เพิ่มลักษณะนิสัย",
      Add_a_trait_to:
        "เพิ่มลักษณะนิสัยในคลัง โปรดตรวจสอบว่ามันไม่ซ้ำ หรือใกล้เคียงเกินไปกับรายการที่มีอยู่แล้ว",
      New_Trait_English: "ลักษณะนิสัย (ภาษาอังกฤษ)",
      New_Trait_Thai: "ลักษณะนิสัย (ภาษาไทย)",
      Enter_trait_here: "ใส่ลักษณะนิสัยที่นี่",
    },
    cultureProfile: {
      Setup_your_Culture_Profile: "กำหนดรูปแบบวัฒนธรรมองค์กรของคุณ",
      The_Happily_Culture_Profile:
        "รูปแบบวัฒนธรรมองค์กร ที่ Happily ใช้นั้นถูกประยุกต์มาจากกรอบแนวคิด การแข่งขันทางค่านิยม (Competing Values Framework) และถูกใช้โดยองค์กรต่าง ๆ มากกว่า 10,000 องค์กรทั่วโลก",
      Identify_the_culture_type:
        "จาก 6 ด้านขององค์กรคุณ ในแต่ละด้านมีวัฒธรรมใดที่โดดเด่นบ้าง มาดูกันว่าระหว่าง การร่วมมือ การควบคุม การแข่งขัน หรือการสร้างสรรค์สิ่งใหม่  คุณให้ความสนใจสิ่งใดมากกว่ากัน",
    },
    acceptableBehaviors: {
      Setup_your_Acceptable_Behaviors: "กำหนดพฤติกรรมที่คุณยอมรับได้",
      Identify_areas_of_misalignment:
        "กำหนดขอบเขตให้กับสิ่งที่อาจเห็นไม่ตรงกันในเรื่องพฤติกรรมที่ยอมรับได้ เพื่อขจัดพฤติกรรมที่เป็นพิษ และสร้างความคาดหวังที่ตรงกัน",
      In_each_scenario:
        "โปรดจัดระดับว่า พฤติกรรมใดยอมรับได้ หรือยอมรับไม่ได้ในแต่ละสถานการณ์ เพื่อเป็นบรรทัดฐานในการเปรียบเทียบ",
      Review_the_acceptable_behaviors:
        "ตรวจสอบพฤติกรรมที่ยอมรับได้ที่คุณต้องการประเมิน เพิ่มหรือลบสถานการณ์ตามต้องการ เราขอแนะนำให้คุณมีสถานการณ์ทั้งหมดไม่เกิน 15 สถานการณ์",
      Workplace_Scenarios: "สถานการณ์สมมติในที่ทำงาน",
      Add_a_workplace_scenario: "สถานการณ์สมมติในที่ทำงาน",
      Add_a_scenario_that:
        "เพิ่มสถานการณ์เพื่อให้องค์กร และผู้ทำแบบทดสอบประเมินว่า ยอมรับได้ ไม่พอใจ ไม่สนับสนุน ควรมีการตำหนิลงโทษ หรือ ยอมรับไม่ได้อย่างเด็ดขาด",
      New_Scenario_English: "สถานการณ์ใหม่ (ภาษาอังกฤษ)",
      New_Scenario_Thai: "สถานการณ์ใหม่ (ภาษาไทย)",
      Enter_scenario_here: "ใส่สถานการณ์สมมติในที่ทำงานที่นี่",
    },
  },
  question: {
    valueAndTraits: {
      Values_and_Traits: "ค่านิยม และลักษณะนิสัย",
      Your_values_guide_your:
        "ค่านิยมจะชี้นำ และแสดงให้เห็นถึงนิสัยและความชอบตามธรรมชาติของคุณ เรามักจะทำสิ่งต่างๆที่สอดคล้องกับค่านิยมของเรา ส่วนลักษณะนิสัยนั้น จะเป็นตัวอธิบายตัวตนของเรา",
      In_this_sectio_you_have: "ในหมวดนี้ คุณมีเวลา ",
      minutes: "นาที",
      to_identify_and_select_your:
        "ในการระบุ และเลือกค่านิยม กับลักษณะนิสัยที่สอดคล้องกับตัวตนของคุณ",
      Select_the: "เลือก",
      values_that_best_describe: "ค่านิยมที่สำคัญที่สุดสำหรับคุณ",
      traits_that_best_describe: "ลักษณะนิสัยที่อธิบายคุณได้ดีที่สุด",
      Click_on_a_word_to_add: "คลิกที่คำเพื่อเพิ่มหรือเอาออกจากตัวเลือก",
      // ---
      SELECT: "เลือก",
      MORE_ITEMS: "ตัวเลือกเพิ่มเติม",
      Rated_very_important_by: "ถูกเลือกว่าสำคัญโดย ",
      but_unselected_by: " แต่ไม่ได้ถูกเลือกโดย ",
    },
    cultureProfile: {
      Culture_Profile: "รูปแบบวัฒนธรรมองค์กร",
      The_Happily_Culture_Profile:
        "รูปแบบวัฒนธรรมองค์กร ที่ Happily ใช้นั้นถูกประยุกต์มาจากกรอบแนวคิด การแข่งขันทางค่านิยม (Competing Values Framework) และถูกใช้โดยองค์กรต่าง ๆ มากกว่า 10,000 องค์กรทั่วโลก",
      Which_culture_type_should:
        "วัฒนธรรมรูปแบบใดที่โดดเด่น? คุณอยากให้ความสำคัญในเรื่องการร่วมมือ การควบคุม การแข่งขัน หรือการสร้างสรรค์สิ่งใหม่มากกว่ากัน",
      Takes: "ใช้เวลา",
      minutes: "นาที",
      to_go_evaluate_your_ideal:
        "ในการประเมินองค์กรในอุดมคติของคุณอิงจาก 6 ด้านที่แตกต่างกัน",
      Assessing_each_aspect_divide:
        "การประเมินในแต่ละด้าน แบ่ง 100 คะแนนออกเป็น 4 ตัวเลือก ให้คะแนนสูงสุดสำหรับตัวเลือกที่คล้ายกับองค์กรที่คุณอยากได้มากที่สุด และให้คะแนนลดลงมากับข้อที่ใกล้เคียงกับสิ่งที่คุณต้องการน้อยที่สุด",
    },
    acceptableBehaviors: {
      Acceptable_Behaviors: "พฤติกรรมที่ยอมรับได้",
      Evaluating_Acceptable_Behaviors: "การประเมินพฤติกรรมที่ยอมรับได้",
      In_the_workplace_it_is_not_uncommon:
        "ไม่ใช่เรื่องแปลกที่จะเจอกับพฤติกรรมที่ไม่พึงประสงค์ในที่ทำงาน ซึ่งบางเรื่องเราก็อาจจะอดทนกับมันได้มากกว่าคนอื่น",
      Please_provide_a_rating_for_each:
        "ช่วยจัดระดับให้กับแต่ละสถานการณ์ อ้างอิงจากความพึงพอใจ ระดับความอดทนและยอมได้ของคุณ",
      Acceptable: "ยอมรับได้",
      This_behavior_is_acceptable: "พฤติกรรมนี้ฉันยอมรับได้",
      Frowned_Upon: "ไม่พอใจ/ไม่ยอมรับ",
      This_behavior_is_not_acceptable:
        "พฤติกรรมนี้ไม่เป็นที่ยอมรับ และฉันอารมณ์เสียอย่างเห็นได้ชัดเมื่อมันเกิดขึ้น",
      Actively_Discouraged: "แสดงออกว่าไม่สนับสนุน",
      This_behavior_is_unacceptable_Discouraged:
        "พฤติกรรมนี้ยอมรับไม่ได้ ฉันจะไม่สนับสนุน และพยายามขัดขวางมัน",
      Reprimanded: "ต้องมีการตำหนิหรือลงโทษ",
      This_behavior_is_unacceptable_Reprimanded:
        "พฤติกรรมนี้ยอมรับไม่ได้ ควรจะมีบทลงโทษ และผู้กระทำต้องได้รับผลจากการกระทำนี้",
      Zero_Tolerance_Policy: "ยอมรับไม่ได้อย่างเด็ดขาด",
      This_behavior_is_irreprehensible:
        "พฤติกรรมนี้สมควรได้รับการประณาม และไม่ควรได้รับโอกาสครั้งที่สอง",
      Scenario: "สถานการณ์",
    },
    workStyleIdentifier: {
      Work_Style_Identifier: "ค้นหาสไตล์การทำงาน",
      identify_your_work_style:
        "ค้นหาสไตล์การทำงานของคุณโดยอิงจากหลัก 3P ที่ย่อมาจาก Product(ผลลัพธ์) Process(ขั้นตอน) และ People(ผู้คน) เพื่อให้เกิดความเข้าใจที่ดีขึ้น ว่าคุณมีวิธีในการจบงานและลำดับเรื่องที่คุณให้ความสำคัญในการทำงานอย่างไร",
      complete_each_workplace_scenario:
        "ตอบคำถามผ่านสถานการณ์สมมติในที่ทำงาน โดยเลือกจากการกระทำหลัก ลำดับความสำคัญ หรือความชอบของคุณ",
      Based_on_your_natural_tendencies:
        "โดยปกติแล้วคุณมีแนวโน้มที่จะตอบสนอง หรือปฏิบัติตัวอย่างไร ในสถานการณ์สมมตินี้",
      SCENARIO: "สถานการณ์",
    },
    humanSkills: {
      Showcase_your_communication_ability: "โปรดตอบคำถามเหล่านี้",
      // ---
      The_best_of_your: "อย่างสุดความสามารถ",
      Be_analytical_by_sharing:
        "เพื่อแสดงทักษะในการสื่อสาร ความเข้าอกเข้าใจ การตระหนักรู้ถึงสถานการณ์ และการคิดวิเคราะห์ โดยอธิบายถึงกระบวนการทางความคิดของคุณ และยกตัวอย่างเพื่อความชัดเจน",
      You_will_have_ten_min: "คุณมีเวลาทั้งสิ้น 10 นาทีในการตอบคำถามทั้งสองข้อ",
      // ---
      Write_your_response: "เขียนคำตอบของคุณที่นี่...",
    },
  },
  assessmentTest: {
    Hello: "สวัสดี",
    minutes_remaining_defore: "เหลือเวลาอีก",
    minutes_remaining_after: "นาที",
    Thank_you_for_taking: "ขอบคุณที่สละเวลาเพื่อทำแบบทดสอบนี้กับเรา",
    // ---
    Thank_you_for_taking_end: "จนจบ",
    A_few_things_you_should: "เกร็ดเล็กน้อยที่คุณควรรู้ก่อนเริ่ม:",
    Plan_to_take: "ควรวางแผนในการทำแบบทดสอบนี้ โดยใช้เวลา ",
    minutes_to_complete_this: " นาทีต่อเนื่อง",
    // ---
    It_is_in_your_best_interest:
      "เพื่อประโยชน์ของคุณเอง ให้ตอบตามสิ่งที่คุณคิดจริงๆ",
    There_is_no_right: "แบบทดสอบนี้ไม่มีคำตอบที่ถูกหรือผิด",
    If_you_re_unsure: "ถ้าไม่มั่นใจว่าจะตอบอะไร ให้เชื่อสัญชาตญาณของตัวเอง",
    Thank_you_for_taking_to_complete: "ขอบคุณที่สละเวลาเพื่อทำแบบทดสอบในหมวด",
    // ---
    Thank_you_for_taking_to_complete_the_assessment:
      "ขอบคุณที่สละเวลาเพื่อทำแบบทดสอบในหมวดโปรไฟล์วัฒนธรรมกับเรา",
    section_of_the_assessment: "กับเรา",
    You_ve_completed_this_section: "คุณทำหมวดนี้เสร็จเรียบร้อยแล้ว",
    You_ve_completed_all_section: "คุณทำครบทุกหมวดเสร็จเรียบร้อยแล้ว",
    You_have_completed_all_sections:
      "คุณทำแบบทดสอบนี้ครบทุกหมวดแล้ว ปิดหน้าต่างนี้ได้",
    Once_you_re_ready: "เมื่อคุณพร้อมแล้ว กรุณาเริ่มทำหมวดถัดไป",
    // ---
    Go_to_the_Next_Section: "ไปที่หมวดถัดไป",
    POINTS_REMAINING: "คะแนนที่เหลืออยู่",
    Optional: "ไม่บังคับ",
    Anyz_feedback_or_thoughts: "ข้อเสนอแนะหรือความคิดที่จะแบ่งปันเกี่ยวกับ ",
    values_and_traits_q: "ค่านิยม และลักษณะนิสัย",
    culture_profile_q: "รูปแบบวัฒนธรรมองค์กร",
    acceptable_behaviors_q: "พฤติกรรมที่ยอมรับได้",
    work_style_identifier_q: "ตัวจำแนกสไตล์การทำงาน",
    human_skills_q: "ทักษะเรื่องคน",
    Describe_a_time_when_you:
      "จงอธิบายเหตุการณ์เมื่อคุณต้องแก้ปัญหาที่ซับซ้อนในการทำงาน กระบวนการทางความคิดของคุณเป็นอย่างไร และคุณหาทางออกได้อย่างไร?",
    Imagine_you_are_working_on:
      "สมมติว่าคุณกำลังทำโครงการร่วมกับทีมโดยมีเวลากระชั้นชิด และคุณพบข้อบกพร่องสำคัญในการออกแบบโครงการ  คุณจะแก้ปัญหาด้วยขั้นตอนอะไรบ้าง และจะทำอย่างไรเพื่อให้มั่นใจว่างานยังคงเป็นไปตามแผนที่วางไว้",
  },
  assessmentResults: {
    Overall: "ภาพรวม",
    Create: "การสร้าง",
    Compete: "การแข่งขัน",
    Control: "การควบคุม",
    Collaborate: "ความร่วมมือ",
    people_oriented: "ที่มุ่งเน้นผู้คน",
    dynamic_entrepreneurial: "ไดนามิกและผู้ประกอบการ",
    results_oriented: "มุ่งเน้นผลงาน",
    process_oriented: "เชิงกระบวนการ",
    Values: "ค่านิยม",
    and_: "และ",
    Traits: "ลักษณะนิสัย",
    Culture_: "โปรไฟล์",
    Profile_: "วัฒนธรรม",
    Acceptable_: "พฤติกรรมที่",
    Behaviors_: "ยอมรับได้",
    SEND_RESULTS_TO: "ส่งผลทดสอบให้",
    RE_SEND_RESULTS_TO: "ส่งผลทดสอบอีกครั้งให้",
    Completed_on: "ทำสำเร็จเมื่อ",
    Score_Summary: "ผลสรุปคะแนน",
    selection_of_the_candidate_values:
      "ส่วนนี้คือค่านิยมที่ผู้ทำแบบทดสอบเลือก โดยแบ่งสีตามลำดับความสำคัญของค่านิยมที่องค์กรมี",
    Our_values_guide_our:
      "ค่านิยมของเรานั้นชี้นำพฤติกรรมตามธรรมชาติ วิธีการทำงาน การกระทำ และการตัดสินใจของเรา",
    This_is_the_selection:
      "ส่วนนี้คือลักษณะนิสัยที่ผู้ทำแบบทดสอบเลือก โดยแบ่งสีตามลำดับความสำคัญของลักษณะนิสัยในอุดมคติขององค์กร",
    Our_traits_guide_give:
      "ลักษณะนิสัยของเรานั้นเป็นตัวชี้นำ และช่วยให้เราสามารถประพฤติ และปฏิบัติได้ตามค่านิยมที่เรามี",
    The_overall_overlap_between:
      "ภาพความทับซ้อนกัน ระหว่างวัฒนธรรมองค์กรในอุดมคติของผู้ทำแบบทดสอบ กับวัฒนธรรมองค์กรของคุณ",
    Hover_over_each_dimension: "เลื่อนเมาส์ไปที่แต่ละมิติเพื่อดู",
    how_each_value_is: "ว่าแต่ละค่านิยมนั้นถูกแสดงออกมาอย่างไรในส่วนนี้",
    A_comparison_between_what:
      "การเปรียบเทียบระหว่างสิ่งที่ผู้ทำแบบทดสอบรู้สึกว่าเป็นพฤติกรรมที่ยอมรับได้ กับสิ่งที่องค์กรยอมรับได้ สำหรับข้อที่มีความไม่ตรงกัน เราแนะนำว่าควรพูดคุยเพื่ออธิบายความคาดหวังให้กระจ่างมากขึ้น",
    The_candidate_primary_work_style:
      "สไตล์การทำงานหลักของผู้ทำแบบทดสอบ จะเป็นตัวชี้นำวิธีการทำงานอันเป็นธรรมชาติของพวกเขา มันไม่ได้แสดงว่าพวกเขาทำได้แค่นั้น เพียงแต่อธิบายว่าพวกเขามักจะชอบทำงานอย่างไร และทำงานกับผู้อื่นอย่างไร",
    Work_Style__: "สัดส่วน",
    Composition__: "สไตล์การทำงาน",
    Product: "ผลลัพธ์ (Product)",
    Doers_and_executors_They:
      "นักปฏิบัติและบริหาร พวกเขาขับเคลื่อนชีวิตด้วยการทำงานให้สำเร็จลุล่วง อย่างไรก็ตามพวกเขาก็มักจะมองข้ามรายละเอียด และอารมณ์ความรู้สึกของคนอื่นๆในระหว่างทางการทำงาน",
    Process: "ขั้นตอน (Process)",
    Learners_and_thinkers_They:
      "นักคิด เรียนรู้และวางแผน พวกเขาเก่งกาจที่สุดในการคิดวิเคราะห์อย่างถี่ถ้วน และเน้นย้ำรายละเอียดในการจะทำอะไรให้สำเร็จ อย่างไรก็ตามพวกเขามักจะใช้เวลามากเกินไปในการทำให้ทุกอย่างออกมาสมบูรณ์แบบ แทนที่จะทำให้มันเสร็จในเวลา หรือใส่ใจดูแลผู้อื่นมากกว่านี้",
    People: "ผู้คน (People)",
    Relationship_builders_They:
      "นักสร้างสัมพันธ์ พวกเขาเข้าใจผู้คน และรู้ว่าจะดึงส่วนที่ดีที่สุดจากคนอื่นๆมาได้อย่างไร อย่างไรก็ตามพวกเขามักจะใช้เวลามากเกินไปในการทำให้คนอื่นรู้สึกดี แทนที่จะติดตามรายละเอียดอื่นๆ หรือทำงานให้เสร็จ",
    Select_a_dimension_to: "เลือก มิติ เพื่อนเรียนรู้เพิ่มเติม",
    Tap_on_a_row_in_the:
      "กดที่แถวด้านซ้ายเพื่อดูความสอดคล้องกันระหว่างคำตอบของผู้ทำสอบทดสอบ และขององค์กร",
    Group_Results: "ผลลัพธ์แบบกลุ่ม",
    ANALYTICS: "การวิเคราะห์",
    Legend: "สัญลักษณ์แสดงข้อมูลรายบุคคล",
    The_overlap_between_the__workplace:
      "ความทับซ้อนกันระหว่างที่ทำงานในอุดมคติของผู้ทำแบบทดสอบ และสิ่งที่องค์กรของคุณเป็น",
    A_human_skills_profile_for:
      "โปรไฟล์ทักษะของมนุษย์สำหรับผู้สมัครแต่ละคนขึ้นอยู่กับการสื่อสารที่เป็นลายลักษณ์อักษร การเอาใจใส่ การรับรู้สถานการณ์ และการคิดเชิงวิพากษ์",
    There_are_no_values_assigned:
      "ไม่มีค่านิยมที่ถูกเลือกไว้สำหรับความสำคัญระดับนี้",
    Progress: "ความคืบหน้า",
    Assessment_Results_for: "ผลทดสอบสำหรับ",
    Human_: "ทักษะ",
    Skills_: "มนุษย์สัมพันธ์",
    The_candidate_human_skills_are:
      "ทักษะเรื่องคนของผู้ทำแบบทดสอบจะถูกประเมินจากการตอบคำถามปลายเปิด โดยเรามีการประเมินการสื่อสารทางการเขียน ความเห็นอกเห็นใจ การตระหนักรู้ในสถานการณ์ และการคิดวิเคราะห์ รวมถึงวิธีการแสดงออกผ่านค่านิยม (โดยการประเมินด้านค่านิยมนั้นจะไม่ถูกนำมารวมคะแนน แต่จะแสดงเพื่อจุดประสงค์ในการหาแนวร่วมที่ตรงกันเท่านั้น)",
    Completion_Time: "เวลาในการทำแบบสอบถาม",
  },
  coreValuesAnalyzer: {
    My_Core_Values: "ค่านิยมของฉัน",
    Audit: "วิเคราะห์ค่านิยม",
    Skills: "ทักษะ",
    Survey_Question: "คำถามแบบทดสอบ",
    Microlearning: "เกร็ดความรู้",
    Clarity: "ความชัดเจน",
    Practical: "การทำได้จริง",
    Best_Practice: "ตรงตามแบบอย่างที่ดี",
    Inspiring: "ความจูงใจ",
    Audit_Summary: "ผลสรุปการวิเคราะห์",
    Purpose_driven: "Purpose-driven", // ทำตามเป้าหมาย
    Alternative_Options: "ข้อเสนอแนะ",
    Auditing_you_values: "กำลังวิเคราะห์ค่านิมของคุณ...",
    Overall: "ภาพรวม",
    Simple_Language: "ความเข้าใจง่าย",
    Potential_Misunderstanding: "โอกาสในการเข้าใจผิด",
    Consistency: "ความสม่ำเสมอ",
    Actionable: "ปฏิบัติได้จริง",
    Easy_To_Reinforce: "ทำตามง่าย",
    Measureable: "การวัดผล",
    Not_Permission_To_Play: "ไม่ใช่สิ่งพื้นฐาน",
    Sense_Of_Pride: "ความภาคภูมิใจ",
    Positive_Impact: "ผลกระทบเชิงบวก",
    Analyze_everything_you:
      "เราจะช่วยวิเคราะห์ทุกอย่างที่คุณต้องรู้เกี่ยวกับค่านิยมองค์กร ให้คำแนะนำในทักษะที่ต้องพัฒนา คำถามที่ควรถาม และข้อมูลที่ควรเผยแพร่",
    Enter_from_1_to: `ใส่ค่านิยมที่นี่ (สูงสุด 8 ค่านิยม)`,
    Analyze: "วิเคราะห์",
    Analyzing: "กำลังวิเคราะห์",
    What_you_get: "คุณจะได้รับ",
    Audit_your_core_values: "การวิเคราะห์ค่านิยม",
    Analyze_how_well_your:
      "เราจะช่วยวิเคราะห์ และให้คะแนนค่านิยมของคุณในเรื่อง ความชัดเจน การทำได้จริง ความตรงตามแบบอย่างที่ดี และความสามารถในการจูงใจ",
    Ratings_recommendations_and:
      "โดยมีการให้คะแนน มอบคำแนะนำ และเสนอค่านิยมทางเลือก เพื่อช่วยพัฒนาค่านิยมองค์กรของคุณ",
    Get_a_Skills_Development: "แผนพัฒนาทักษะ",
    Get_a_skill_evelopment:
      "เราจะแนะนำวิธีพัฒนาทักษะที่จะช่วยให้พนักงานของคุณสามารถแสดงออก และปฏิบัติตามค่านิยมของคุณได้",
    Leaders_an_encourage_and:
      "อย่างไรก็ตามผู้นำองค์กรควรจะสนับสนุน โดยการมอบรางวัลให้กับพนักงานที่ปฏิบัติตามค่านิยมดังกล่าว สร้างโอกาสให้พนักงานสามารถฝึกฝน และพัฒนาทักษะเหล่านี้ได้",
    Generate_Survey_Questions: "คำถามแบบทดสอบ",
    Help_your_employees_regularly:
      "คุณสามารถช่วยให้พนักงานตกตะกอน และแบ่งปันฟีดแบ็กอย่างสม่ำเสมอตามค่านิยมองค์กร โดยเพิ่มคำถามที่เราเสนอแนะไปในแบบสอบถาม หรือเครื่องมือการมอบฟีดแบ็กที่คุณเลือกใช้",
    Learning_Content_to_Support: "ชุดความรู้เพื่อส่งเสริมค่านิยม",
    // --
    Get_micro_learning_content:
      "เรามอบเกร็ดความรู้ (Micro-learning) เพื่อให้คุณสามารถนำไปเผยแพร่ผ่านช่องทางต่างๆ เช่น อีเมล เว็บไซต์องค์กร สื่อโซเซียล หรือ ระบบการจัดการการเรียนรู้",
    Short_and_easily_digestible:
      "โดยเราทำให้สั้น และย่อยได้ง่าย ทำให้พนักงานสามารถตกตะกอน และพัฒนาตนตามค่านิยมได้อย่างง่ายดายและรวดเร็ว",
    Skills_and_Development_Guidance: "ทักษะที่ควรพัฒนา",
    In_order_for_your:
      "เพื่อให้พนักงานสามารถแสดงออก และแสดงความจริงใจต่อค่านิยมองค์กร พวกเขาต้องมี หรือพัฒนาทักษะเหล่านี้อย่างต่อเนื่อง",
    By_veloping_and_supporting:
      "การพัฒนา และส่งเสริมทักษะเหล่านี้ จะทำให้พนักงานสามารถแสดงออก และปฏิบัติตามค่านิยมด้าน",
    Leaders_can_encourage_and:
      "ผู้นำองค์กรควรจะสนับสนุน และมอบรางวัลให้กับพนักงานที่ปฏิบัติตามค่านิยมดังกล่าว และสร้างโอกาสให้พนักงานสามารถฝึกฝน และพัฒนาทักษะเหล่านี้ได้",
    Questions: "คำถาม",
    Regularly_get_your_employees:
      "ลองเพิ่มคำถามเหล่านี้ไปในแบบสอบถาม หรือเครื่องมือการมอบฟีดแบ็กที่คุณเลือกใช้ เพื่อให้พนักงานได้ตกตะกอน และแบ่งปันฟีดแบ็กอย่างสม่ำเสมอ",
    These_micro_learning_content:
      "เกร็ดความรู้ (Micro-learning) นั้นถูกสร้างมาให้กระชับ และย่อยง่าย เพื่อให้พนักงานสามารถตกตะกอน และพัฒนาตามแบบอย่างค่านิยมองค์กรได้อย่างง่ายดายและรวดเร็ว  โดยคุณสามารถเอาไปแชร์ได้หลากหลายช่องทาง เช่น อีเมล เว็บไซต์องค์กร สื่อโซเซียล หรือ ระบบการจัดการการเรียนรู้",
    Generate_more_content: "สร้างเพิ่ม",
    Create_an_account_to:
      "สร้างบัญชีเพื่อปลดล็อค ทักษะ คำถามแบบสอบถาม และ เกร็ดความรู้",
    Download_This_Report: "ดาวน์โหลดรายงานนี้",
    Download_to_save: "บันทึกการตรวจสอบและวิเคราะห์ค่านิยมหลักนี้เป็นไฟล์ PDF",
    Download: "ดาวน์โหลด",
    Download_pdf_title:
      "รายงานค่านิยมหลัก: การตรวจสอบ คำแนะนำด้านทักษะและการพัฒนา คำถามแบบสำรวจ และเกร็ดความรู้",
    Download_pdf_success: "กำลังเตรียมการดาวน์โหลดรายงาน...",
  },
  headerTable: {
    NAME: "ชื่อ",
    CANDIDATES: "ผู้ทำแบบทดสอบ",
    PROGRESS: "ความคืบหน้า",
    LAST_ACTIVITY: "กิจกรรมล่าสุด",
    DATE_CREATED: "วันที่ถูกสร้าง",
    ACTIONS: "ตัวเลือกเพิ่มเติม",
    JOINED: "เข้าร่วมเมื่อ",
    SCORE: "คะแนน",
    STATUS: "สถานะ",
    YOUR_RATING: "ระดับดาว",
  },
  popup: {
    optional: {
      Yes: "ตกลง",
      Yes_chi: "ใช่",
      Confirm: "ยืนยัน",
      Cancel: "ยกเลิก",
      Continue: "ต่อไป",
      Go_to_Next_Section: "Go to Next Section",
    },
    question: {
      question_box_1: {
        title: "ส่งผลทดสอบให้ ",
        question_1: "เราจะส่งลิงค์ส่วนตัวเพื่อเข้าถึงผลลัพธ์แบบทดสอบ ให้กับ  ",
        question_2:
          " ทางอีเมล ภายใต้ชื่อของคุณ คุณต้องการดำเนินการต่อใช่หรือไม่",
      },
      question_box_2: {
        title: "คำเตือน",
        question: "คุณแน่ใจที่จะลบแบบทดสอบนี้หรือไม่",
      },
      question_box_3: {
        title: "คำเตือน",
        question: "คุณแน่ใจที่จะลบผู้ทำแบบทดสอบคนนี้หรือไม่",
      },
      question_box_4: {
        title: "การกำหนดค่า",
        question: "คุณต้องการแก้ไขการกำหนดค่าปัจจุบันของคุณหรือไม่",
      },
      question_box_5: {
        title: "คำเตือน",
        question: "คุณแน่ใจรึเปล่าว่าต้องการนำสถานการณ์นี้ออกไปจากแบบทดสอบ",
        // ---
      },
    },
    alert: {
      alert_box_1: {
        title: "หมดเวลาแล้ว",
        description:
          "เวลาสำหรับส่วนนี้ได้หมดลงแล้ว โปรดดำเนินการไปขั้นตอนถัดไป",
      },
      alert_box_2: {
        title: "เซสชันหมดเวลา",
        description:
          "โปรดไปต่อที่ส่วนถัดไปของแบบทดสอบ เพื่อส่งแบบทดสอบ มิเช่นนั้น คำตอบของคุณอาจเสี่ยงที่จะหาย และคุณอาจจะต้องทำแบบทดสอบอีกรอบ",
      },
      question_box_5: {
        title: "คำเตือน",
        question: "คุณแน่ใจรึเปล่าว่าต้องการนำสถานการณ์นี้ออกไปจากแบบทดสอบ",
        // ---
      },
    },
    message: {
      message_1: "คุณได้ส่งผลลัพธ์แบบทดสอบแล้ว",
      message_2: "อีเมลเชิญได้ถูกส่งให้กับ  ",
      message_3_1: "ผลลัพธ์แบบทดสอบของ ",
      message_3_2: " ได้ถูกลบออกเรียบร้อย",
      message_4: "ไม่พบขอมูล!",
      message_5e: "รูปภาพต้องเป็นไฟล์ JPG หรือ PNG เท่านั้น",
      message_6e: "รูปภาพนี้มีขนาดใหญ่เกิน 2MB",
      message_7e: "อีเมล หรือ OTP ไม่ถูกต้อง",
      message_8: "คัดลอกไปยังคลิปบอร์ด",
      message_9e: "เหลือ 0 ในเดือนนี้",
      message_10e: "จำนวนอีเมลที่สามารถส่งได้สูงสุดคือ ",
      message_11: "ส่งอีเมลแล้ว",
      message_12: "ไม่พบขอมูล!",
      message_13_1: "ส่งอีเมลไปยัง ",
      message_13_2: " แล้ว ",
      message_14w: "ขั้นต่ำ 1 หมวด!",
      message_15w: "โปรดกำหนดค่าให้สำเร็จ",
      message_16e: "อุ้บส์ มีบางอย่างผิดพลาด",
      message_17: "คัดลอก Referral Link ไปยังคลิปบอร์ด",
      message_18: "บักทึก ข้อมูลผู้ใช้งานได้ถูกอัปเดตแล้ว",
      message_19: "ลิงก์ถูกคัดลอกไปยังคลิปบอร์ดของคุณแล้ว",
      message_20w: "คุณสามารถมีผู้รับได้สูงสุด 100 คน",
    },
  },
  datetime: {
    months: {
      January: "มกราคม",
      February: "กุมภาพันธ์",
      March: "มีนาคม",
      April: "เมษายน",
      May: "พฤษภาคม",
      June: "มิถุนายน",
      July: "กรกฎาคม",
      August: "สิงหาคม",
      September: "กันยายน",
      October: "ตุลาคม",
      November: "พฤศจิกายน",
      December: "ธันวาคม",
    },
  },
  coupon: {
    error_messages: {
      message_1: "กรุณากรอกรหัสคูปอง",
      message_2: "ไม่พบรหัสคูปอง",
      message_3: "รหัสคูปองนี้หมดอายุแล้ว",
      message_4: "รหัสคูปองนี้ถูกใช้หมดโควต้าแล้ว",
      message_5: "รหัสคูปองนี้ถูกใช้ไปแล้ว",
    },
  },
};

export default th;
