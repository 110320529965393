
import { defineComponent } from "vue";
import helpers from "@/helpers/global";

export default defineComponent({
  name: "Navbar",
  async created() {
    await this.$store.dispatch("user/loadCurrentUser", null, { root: true });
  },
  mounted() {
    this.$store.dispatch("quota/load", this.user, { root: true });
  },
  computed: {
    companyColor() {
      const defaultCompanyColor = "#000000";
      const user: any = this.$store.getters["user/user"] || {};
      if (user && user.color) {
        return user.color;
      }
      return defaultCompanyColor;
    },
    userDisplayName(): string {
      return helpers.getUserDisplayName(this.$store.getters["auth/user"]);
    },
    paymentEnabled(): boolean {
      return process.env.VUE_APP_PAYMENT_ENABLED === "on";
    },
    quota(): any {
      return this.$store.getters["quota/data"];
    },
    user(): any {
      return this.$store.getters["auth/user"];
    },
    companyLogoUrl(): any {
      const defaultLogoUrl = "";
      const companyLogoUrl = this.$store.getters["user/companyLogoUrl"];
      const userDataEncoded: any = localStorage.getItem("userData");
      if (userDataEncoded) {
        try {
          const userData = JSON.parse(atob(userDataEncoded) || "{}");
          if (userData.email) {
            return companyLogoUrl;
          } else {
            return defaultLogoUrl;
          }
        } catch (e: any) {
          console.error(e);
        }
      } else {
        return defaultLogoUrl;
      }
      return "";
    },
  },
  data() {
    return {
      companyImage: "",
    };
  },
  methods: {
    menuActiveStyleBar() {
      if ("core-values" == this.$route.meta.navbarName) {
        return "menu-1";
      } else if ("assessments" == this.$route.meta.navbarName) {
        return "menu-2";
      } else if ("respondents" == this.$route.meta.navbarName) {
        return "menu-3";
      }
      return "";
    },
    async handleCommand(command: string) {
      switch (command) {
        case "logout":
          await this.$store.dispatch("auth/logout");
          window.location.href = "/login";
          break;
        case "my_account":
          this.$router.push({ name: "MyAccount" });
          break;
      }
    },
    gotoPaymentPage() {
      this.$router.push({
        name: "MyAccountWithTab",
        params: { tab: "plan-billing" },
      });
    },
    onClickMyCoreValues() {
      const user = this.$store.getters["user/user"];
      const to = helpers.getMyCoreValuesRoute(user);
      this.$router.push(to);
    },
  },
});
