import { getFirestore, doc, getDoc, setDoc } from "firebase/firestore";

import _ from "lodash";
import {
  default as helpers,
  DEFAULT_TIMEZONE,
  DEFAULT_DATE_FORMAT,
} from "@/helpers/global";
import add from "date-fns/add";
import { formatInTimeZone } from "date-fns-tz";

const NS = "quota";

interface Quota {
  quota_total: number;
  expired_at?: string;
}

function getExpiredAt(now?: string, monthTotal = 1, days = 1): string {
  if (!now) now = helpers.now();
  if (helpers.isSafari()) {
    now = now.replace(/-/g, "/");
  }
  const date = new Date(now);
  const duration = {
    months: monthTotal,
    days: days,
  };
  const expiredDate = add(date, duration);
  const format = DEFAULT_DATE_FORMAT.replace("HH:mm:ss", "23:59:59");
  return formatInTimeZone(expiredDate, DEFAULT_TIMEZONE, format);
}

export default {
  namespaced: true,
  state: {
    error: null,
    loading: false,
    data: null,
    canInvite: false,
  },
  mutations: {
    error(state: { error: boolean }, error: boolean): any {
      state.error = error;
    },
    loading(state: { loading: boolean }, loading: boolean): any {
      state.loading = loading;
    },
    data(state: { data: Quota }, data: Quota): any {
      state.data = data;
    },
    canInvite(state: { canInvite: boolean }, canInvite: boolean): any {
      state.canInvite = canInvite;
    },
  },
  getters: {
    error(state: { error: boolean }): any {
      return state.error;
    },
    loading(state: { loading: boolean }): any {
      return state.loading;
    },
    data(state: { data: Quota }): Quota {
      return state.data;
    },
    canInvite(state: { canInvite: boolean }): any {
      return state.canInvite;
    },
  },
  actions: {
    async upsertQuota(
      { commit }: { commit: any },
      { email, formDetails }: { email: string; formDetails: Quota }
    ): Promise<any> {
      // @todo validate email
      if (!email) {
        commit("error", "Invalid email");
        return;
      }
      commit("loading", true);
      const _id = helpers.emailKey(email);
      const $db = getFirestore();
      await setDoc(doc($db, "quota", _id), formDetails, { merge: true });
      commit("loading", false);
    },
    async createDefaultQuota(
      { dispatch }: { dispatch: any },
      { email }: { email: string }
    ): Promise<any> {
      const now = helpers.now();
      // const expiredAt = getExpiredAt(now, 1);
      const formDetails = {
        quota_used: 0,
        quota_remaining: 5,
        quota_total: 5,
        default_quota_total: 5,
        advanced_quota_total: 0,
        unlimited: false,
        // expired_at: expiredAt,
        plan: "free",
        free_monthly_reset_at: getExpiredAt(now, 1, 0),
        created_at: now,
      };
      await dispatch(
        NS + "/upsertQuota",
        { email, formDetails },
        { root: true }
      );
    },
    // @todo firestore function: update expired at (date)
    async updateExpiredAt(
      { commit, dispatch }: { commit: any; dispatch: any },
      { email, months, plan }: { email: string; plan: string; months: number }
    ): Promise<any> {
      const _id = helpers.emailKey(email);
      const $db = getFirestore();
      const docRef = doc($db, "quota", _id);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const data = docSnap.data();
        const now = helpers.now();
        const formDetails = {
          plan: plan,
          unlimited: true,
          expired_at: getExpiredAt(data.expired_at, months),
          updated_at: now,
        };
        await dispatch(
          "quota/upsertQuota",
          { email: email, formDetails: formDetails },
          { root: true }
        );
      } else {
        commit("error", "Quota was not found.");
      }
    },
    // @todo firestore function: event after paid then checking SKU
    async addAdvancedQuotaTotal(
      { commit, dispatch }: { commit: any; dispatch: any },
      { email, credit, plan }: { email: string; credit: number; plan: string }
    ): Promise<any> {
      const _id = helpers.emailKey(email);

      const $db = getFirestore();
      const docRef = doc($db, "quota", _id);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const now = helpers.now();
        const data = docSnap.data();
        // console.log(data, "data");
        credit = Number(credit);
        data.advanced_quota_total =
          parseInt(data.advanced_quota_total) + credit;

        // @todo on change (firestore function)
        data.quota_total = parseInt(data.quota_total) + credit;
        data.quota_remaining = parseInt(data.quota_remaining) + credit;

        data.plan = plan;
        data.updated_at = now;

        const fields = [
          "plan",
          "advanced_quota_total",
          "quota_total",
          "quota_remaining",
          "updated_at",
        ];
        const formDetails = _.pick(data, fields);
        await dispatch(
          NS + "/upsertQuota",
          { email, formDetails },
          { root: true }
        );
      } else {
        commit("error", "Not found user: " + email);
      }
    },
    // @todo firestore function: event on completed assessment
    // WARNING: updateAcceptableBehavior (prevent RECOMPUTE)
    async processQuotaSummariesAfterCompleted(
      { commit }: { commit: any },
      params: { doc: any }
    ): Promise<any> {
      commit("loading", true);
      const document = params.doc;
      const data = document.data();

      if (data.status != "COMPLETED") {
        commit("loading", false);
        return;
      }

      // get quota of company of doc
      const creator = data.creator;
      const _id = helpers.emailKey(creator);
      const $db = getFirestore();
      const docRef = doc($db, "quota", _id);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const quotaData = docSnap.data();
        const fields = [
          "advanced_quota_total",
          "plan",
          "quota_remaining",
          "quota_total",
          "quota_used",
        ];
        const updatedData = _.pick(quotaData, fields);
        updatedData.quota_used++;
        updatedData.quota_remaining--;
        updatedData.quota_remaining = Math.max(updatedData.quota_remaining, 0);

        // @todo keep transactios, logs, can describe what's happen
        const now = helpers.now();
        updatedData.updated_at = now;
        await setDoc(docRef, updatedData, {
          merge: true,
        });
      }

      commit("loading", false);
    },
    async load(
      { commit, dispatch }: { commit: any; dispatch: any },
      { email }: { email: string }
    ): Promise<any> {
      if (!email) return;
      commit("loading", true);
      const _id = helpers.emailKey(email);

      const checkCanInvite = function (data: any): any {
        if (process.env.VUE_APP_PAYMENT_ENABLED !== "on") return true;
        if (!data) return false;
        if (data.unlimited) return true;
        return parseInt(data.quota_remaining) > 0;
      };

      const $db = getFirestore();
      const docRef = doc($db, "quota", _id);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const data = docSnap.data();
        commit("data", data);
        commit("canInvite", checkCanInvite(data));
      } else {
        // auto create in firestore funciton (cloud function)
        // @todo bind this on login (setup al) or on create new user
        await dispatch(
          "quota/createDefaultQuota",
          { email: email },
          { root: true }
        );
        const docRef = doc($db, "quota", _id);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const data = docSnap.data();
          commit("data", data);
          commit("canInvite", checkCanInvite(data));
        } else {
          commit("error", `Can't create quota for user: ${email}`);
        }
      }
      commit("loading", false);
    },
  },
};
