
import { defineComponent } from "vue";
import helpers from "@/helpers/global";
import { ElMessageBox } from "element-plus";

export default defineComponent({
  name: "HelloWorld",
  computed: {
    dataFiltered() {
      const fillter = this.fillterValue;
      const data = JSON.parse(JSON.stringify(this.tableData));

      if (fillter == "name_ascending") {
        return data.sort((a: any, b: any) =>
          a.form_name > b.form_name ? 1 : b.form_name > a.form_name ? -1 : 0
        );
      } else if (fillter == "name_descending") {
        return data.sort((a: any, b: any) =>
          a.form_name < b.form_name ? 1 : b.form_name < a.form_name ? -1 : 0
        );
      } else if (fillter == "candidate_ascending") {
        return data.sort((a: any, b: any) =>
          a.candidate_total > b.candidate_total
            ? 1
            : b.candidate_total > a.candidate_total
            ? -1
            : 0
        );
      } else if (fillter == "candidate_descending") {
        return data.sort((a: any, b: any) =>
          a.candidate_total < b.candidate_total
            ? 1
            : b.candidate_total < a.candidate_total
            ? -1
            : 0
        );
      } else if (fillter == "last_activity_ascending") {
        return data.sort((a: any, b: any) =>
          a.last_activity_at > b.last_activity_at
            ? 1
            : b.last_activity_at > a.last_activity_at
            ? -1
            : 0
        );
      } else if (fillter == "last_activity_ascending") {
        return data.sort((a: any, b: any) =>
          a.last_activity_at < b.last_activity_at
            ? 1
            : b.last_activity_at < a.last_activity_at
            ? -1
            : 0
        );
      } else if (fillter == "date_created_ascending") {
        return data.sort((a: any, b: any) =>
          a.published_at > b.published_at
            ? 1
            : b.published_at > a.published_at
            ? -1
            : 0
        );
      } else if (fillter == "date_created_ascending") {
        return data.sort((a: any, b: any) =>
          a.published_at < b.published_at
            ? 1
            : b.published_at < a.published_at
            ? -1
            : 0
        );
      } else {
        return data;
      }
    },
    tableData() {
      return this.$store.getters["assessmentTemplate/items"];
    },
    loading() {
      return this.$store.getters["assessmentTemplate/loading"];
    },
  },
  data() {
    return {
      fillterValue: "",
    };
  },
  methods: {
    filterActiveClass(value: string) {
      if (this.fillterValue == value) {
        return "active";
      }
      return "";
    },
    barWidht(progress: number, candidates: number) {
      const pergressPercentage = (progress / candidates) * 100;
      return `width: ${pergressPercentage.toFixed(1)}%`;
    },
    fontColor(progress: number, candidates: number) {
      const pergressPercentage = (progress / candidates) * 100;
      if (+pergressPercentage.toFixed(1) > 50) {
        return "fc-FFFFFF";
      }
      return "text-shadow";
    },
    getLocaleDate(time: string) {
      return helpers.getLocaleDate(time);
    },
    timeAgo(time: string) {
      return helpers.timeAgo(time);
    },
    async handleCommand(command: string, row: any) {
      const routeId = row.routeId;
      switch (command) {
        case "edit":
          this.$router.replace(`/assessment/edit/${row.routeId}`);
          break;
        case "clone":
          await this.$store.dispatch(
            "assessmentTemplate/clone",
            {
              routeId: routeId,
            },
            { root: true }
          );
          this.loadTables();
          break;
        case "delete":
          ElMessageBox.confirm(
            (this as any).$t("popup.question.question_box_2.title"),
            (this as any).$t("popup.question.question_box_2.question"),
            {
              confirmButtonText: (this as any).$t("popup.optional.Yes_chi"),
              cancelButtonText: (this as any).$t("popup.optional.Cancel"),
              type: "warning",
            }
          )
            .then(async () => {
              await this.$store.dispatch("assessmentTemplate/softDelete", row, {
                root: true,
              });
              this.loadTables();
            })
            .catch((error) => {
              if (error == "cancel") return;
              console.error(error);
            });
      }
    },
    loadTables() {
      this.$store.dispatch(
        "assessmentTemplate/getList",
        { page: 1 },
        { root: true }
      );
    },
  },
});
