const en = {
  lang_code: "en",
  f_thai: "ไทย",
  thai: "Thai",
  f_english: "english",
  English: "English",
  test: "test",
  main: {
    dot: ".",
    Values_and_Traits: "Values & Traits",
    Culture_Profile: "Culture Profile",
    Acceptable_Behaviors: "Acceptable Behaviors",
    Work_Style_Identifier: "Work Style Identifier",
    Human_Skills: "Human Skills",
  },
  placeholder: {
    full_name: "full name",
    email: "email",
    email_email_com: "email@email.com",
    Work_email: "Work email",
    First_name: "First name",
    Last_name: "Last name",
    Company_name: "Company name",
    What_is_your: "What is your job title",
    The_number_of_hires: "The number of hires per year (estimate)?",
    What_level_are_you: "What level are you at with your culture development?",
    Coupon_code: "Coupon code",
    Email_Address_start: "Email Address*",
    Search_by_name_or: "Search by name or email address",
    Assessment: "Assessment",
    Your_rating: "Your rating",
    Select: "Select",
    Search: "Search",
    Name_your_assessment: "Name your assessment",
    What_is_this_assessment_for: "What is this assessment for?",
    Number_of_employees: "Number of employees",
  },
  btn: {
    Continue: "Continue",
    ADD: "ADD",
    Next: "Next",
    Skip: "Skip",
    Save: "Save",
    Edit: "Edit",
    Clone: "Clone",
    Delete: "Delete",
    Invite: "Invite",
    Quick_Invite: "Quick Invite",
    Get_Started: "Get Started",
    Close: "Close",
    Previous: "Previous",
    Finish: "Finish",
    Submit: "Submit",
    Remove: "Remove",
    Do_it_later: "Do it later",
    Copy: "Copy",
    Send: "Send",
    Re_send_Invit: "Re-send Invite",
    Clear_Results: "Clear Results",
    Cancel: "Cancel",
  },
  optional: {
    Hiring_candidates: "Hiring candidates",
    New_Hires: "Onboarding new employees",
    Current_team_members: "Team building activity",
    Invited: "Invited",
    Started: "Started",
    Incompleted: "Incomplete",
    Completed: "Completed",
  },
  login: {
    Please_confirm_who_you: "Please confirm who you are",
    I_have_read_and: "I have read and I accept the ",
    terms_of_use: "terms of use",
    Send_me_free_stuff:
      "Send me free stuff and occasional emails about Happily’s services",
  },
  timeout: {
    No_time_remaining: "No time remaining",
    The_allocated_time_for_this_section:
      "The allocated time for this section has run out. Please continue to the next step.",
    Session_Time_Out: "Session Time Out",
    Please_proceed_to_the_next_section:
      "Please proceed to the next section of the assessment to complete your submission. Otherwise, you are at risk of losing your responses and may have to re-take the assessment.",
  },
  pincode: {
    Enter_your_PIN_code: "Enter your PIN-code sent to",
    Sign_in_using_a: "Sign in using a different account",
  },
  createAccount: {
    Get_started_with_your: "Get started with your free assessment.",
    Create_my_free_account: "Create my free account",
    Already_have_a: "Already have a Happily account?",
    Log_in_to: "Log in to your account",
    Log_in: "Log in",
    Sign_up: "Sign up",
    Do_not_have: "Don’t have an account yet?",
    OR: "OR",
    Continue_with_Google: "Continue with Google",
    Continue_with_Microsoft: "Continue with Microsoft Account",
    By_signing_up: "By signing up, I’ve read the",
    Terms_and_Conditions: "Terms and Conditions",
    Tell_us_a_bit: "Tell us a bit about yourself and your company.",
    Give_your_assessments:
      "Give your assessments your company’s look and feel.",
    Upload_Logo: "Upload Logo",
    Finish_sign_up: "Finish sign-up",
    more_than: "more than",
  },
  myAccount: {
    Log_out: "Log out",
    My_Account: "My Account",
    My_Profile: "My Profile",
    My_Company: "My Company",
    Plan_and_Billing: "Plan & Billing",
    Refer_a_friend: "Refer a friend (free credits)",
    First_Name: "First Name",
    Last_Name: "Last Name",
    Language: "Language",
    Company_Name: "Company Name",
    Logo: "Logo",
    Upload_Logo: "Upload Logo",
    Brand_Color: "Brand Color",
    Unlimited: "Unlimited",
    __remaining: "",
    credit: "credit",
    credits: "credits",
    remaining: "remaining",
    this_month: "this month",
    Add_Credit: "Add Credits",
    refer_a_friend: {
      Special_offer: "Special offer",
      Refer_a_friend_and: "Refer a friend and you both earn +10 free credits!",
      When_a_friend_signs:
        "When a friend signs up for My Culture and creates an assessment, you’ll both earn 10 free credits.",
      Your_unique_referral_link: "Your unique referral link",
      Email_your_invite: "Email your invite",
      we_do_not_recognise: "Sorry, we don't recognize this email address.",
      Successful_Referrals: "Successful Referrals",
    },
    planAndBill: {
      Your_current_plan: "Your current plan",
      Free_Plan: "Free Plan",
      Unlimited_credits: "Unlimited credits",
      You_are_used: "You've used",
      out_of: "out of",
      credits: "credits",
      credits_remaining: "credits remaining",
      this_mounth: "this month",
      A_credit_gets_used:
        "A credit gets used up when a respondent completes an assessment.",
      You_must_have_available:
        "You must have available credits to send out invitations.",
      All_plans_have_access_to_the_same_benefit:
        "All plans have access to the same benefit",
      Unlimited_assessment_variations: "Unlimited assessment variations",
      Access_to_all_available:
        "Access to all available sections (regularly updated)",
      Group_analytics: "Group analytics",
      Bulk_invites: "Bulk invites",
      Custom_branding: "Custom branding",
      Email_Support: "Email support",
      Continue: "Continue",
      Downgrade_to: "Downgrade to",
      Upgrade_to: "Upgrade to",
      Pay_as_you_go: "Pay as you go",
      For_businesses_that_occasionally:
        "For businesses that hire occasionally and want flexibility.",
      Pay_one_per_credit: " Pay $1 per credit.",
      Buy_credit: "Buy credits",
      Cancel_Subscription: "Cancel Subscription",
      Minimum_purchase_10_credits: "Minimum purchase 10 credits",
      Manage_your_plan: "Manage your plan",
      Annual_Subscriptionogo: "Annual Subscription",
      Monthly_Subscription: "Monthly Subscription",
      For_businesses_wiht_high: "For businesses with high-volume hiring needs.",
      // ---
      _49_per_month: " Pay $49 per month",
      for_unlimited_credits: "for unlimited credits.",
      Subscribe: "Subscribe",
      Change_to_Annual: "Change to Annual",
      Change_to_Monthly: "Change to Monthly",
      Select_the_annual_plan: "Select the annual plan for a 25% discount",
      PAY_AS_YOU_GO: "PAY AS YOU GO",
      Available_credits_allow_you:
        "Available credits allow you to send out invitations and activates your invitation link. A credit is consumed when someone completes an assessment.",
      Buy_10_for_S10: "Buy 10 for $10",
      Buy_20_for_S18: "Buy 20 for $18 (10% off)",
      Buy_30_for_S24: "Buy 30 for $24 (20% off",
      Buy_40_for_S28: "Buy 40 for $28 (30% off)",
      Redeem: "Redeem",
      SUBSCRIPTION: "SUBSCRIPTION",
      Access_unlimited_credits_during:
        "Access unlimited credits during your subscription period. Cancel at any time to ensure your selected plan doesn’t renew.",
      Monthly_for_S49: "Monthly for $49",
      Billed_today_and_on: "Billed today and on the 6th of each mont",
      Annual_for_S441: "Annual for $441 (25% off)",
      Billed_today_and_next: "Billed today and next on Dec 6, 2023",
      Coupon: "Coupon",
      Redeem_Free_Credits: "Redeem Free Credits",
      Enter_your_coupon:
        "Enter your coupon code here. Want some free credits? Reach out to us at ",
      sales_happily_ai: "sales@happily.ai",
      Coupon_Code: "Coupon Code",
      We_re_sorry: "We’re sorry, this code is invalid.",
      credits_redeemed_1: "",
      credits_redeemed_2: "credits redeemed",
      You_have_successfully:
        "You have successfully redeemed credits using your coupon code.",
      Failed_redeem: "Failed redeem",
    },
    paymentInteruptPopup: {
      title: "Payments coming soon",
      body: "Thank you for your interest to purchase credits. It's coming soon. In the meantime, refer a friend to earn +10 free credits.",
      confirmButtonText: "Refer a friend",
      cancelButtonText: "Cancel",
    },
  },
  fourZeroFour: {
    Looks_like_something: "Looks like something went wrong",
    the_page_or: "the page or link you are looking for is not available!",
    Our_Website: "Our Website",
  },
  assessments: {
    My_Core_Values: "My Core Values",
    My_assessments: "My Assessments",
    My_respondents: "My Respondents",
    Create_new_assessment: "Create new assessment",
    Get_started: "Get started",
    by_creating_a_new: "by creating a new assessment",
  },
  assessmentsDetails: {
    sections: "sections",
    INVITE_CANDIDATES: "INVITE CANDIDATES",
    By_public_link: "By public link",
    By_Email: "By Email",
    Invite_in_Bulk: "Invite in Bulk",
    INVITE_MULTIPLE_CANDIDATES: "INVITE MULTIPLE CANDIDATES",
    by_Email: "by Email",
    emails_detected: " emails detected",
    Comma_separate_multiple: "Comma separate multiple email addresses",
    Personalized_Bulk_Invites: "Personalized & Bulk Invites",
    PERSONALIZED_BULK_INVITES: "PERSONALIZED & BULK INVITES",
    Email_Subject: "Email Subject",
    Email_Body: "Email Body",
    Email_Sender: "Email Sender",
    Recipient_s: "Recipient(s)",
    Tip_Paste_contact: "Tip: Paste contact info from a spreadsheet",
    First_Name: "First Name",
    Last_Name: "Last Name",
    Email: "Email",
    Send_Test_Email: "Send Test Email",
    Send_Invite: "Send Invite",
    The_email_will_include: "The email will include a header and footer",
  },
  create: {
    Create_new_assessment: "Create new assessment",
    Untitled_assessment: "Untitled assessment",
    Next_Step: "Next Step",
    Name_Assessment: "Name Assessment",
    Select_Sections: "Select Sections",
    Your_Culture_Model: "Your Culture Model",
    Publish: "Publish",
    Review_and_Publish: "Review & Publish",
    Details: "Details",
    Selected: "Selected",
    Select: "Select",
    Values_and_Traits: "Values & Traits",
    Values_and_traits_influence:
      "Values and traits influence behavior. When our values are aligned, working together is easy and natural. And when our desired traits are aligned, we can exercise these values in daily work.",
    Culture_Profile: "Culture Profile",
    Organizational_culture_is_driven:
      "Organizational culture is driven by competing values. Identify and match culture profiles based on",
    Clan_Create_Collaborate_and: "Collaborate, Create, Compete, and Control",
    // ---
    across_six_dimensions_of: "across six dimensions of work.",
    Determine_culture_profile: "Determine culture profile fit.",
    Determine_values_traits: "Determine value & trait fit.",
    // ---
    Behaviors_are_considered_toxic:
      "Behaviors are considered toxic when they don’t align with what’s expected. Understand behaviors that are acceptable, discouraged, or not-tolerated and identify mismatches.",
    Determine_expected_behaviors_and:
      "Determine expected behaviors and consequences.",
    Acceptable_Behaviors: "Acceptable Behaviors",
    Work_Style_Identifier: "Work Style Identifier",
    Identify_a_candidate_work_style:
      "Identify a candidate’s work style based on the 3P framework.",
    Product_Process_or_People: "Product, Process, or People.",
    // ---
    Understanding_the_inclination_for:
      "Understanding the inclination for each work style provides insight into how work is completed and prioritized.",
    Setup_your_company_values:
      "Setup your company values and traits to identify the attributes that makes your workplace unique.",
    Setup_your_culture_profile:
      "Setup your culture profile by identifying your balance between competing values across 6 dimensions of work.",
    Setup_your_acceptable_behaviors:
      "Setup your acceptable behaviors by going through workplace scenarios and deciding what is acceptable and what’s not.",
    No_set_up_required:
      "No set up required to assess work styles according to the 3P framework.",
    Communication_Styles: "Communication Styles",
    Ready: "Ready",
    Set_up: "Set up",
    SECTIONS: "SECTIONS",
    DURATION: "DURATION",
    Languages_required_to_support: "Languages required to support",
    Advanced_Options: "ADVANCED OPTIONS",
    Additional_configurations_to:
      "Additional configurations to apply to your assessment.",
    Extra_Time: "Extra Time",
    min: "min",
    Sharing_Results: "Sharing Results",
    Auto_send_assessment: "Auto-send assessment results to the respondent",
    Open_ended_Feedback: "Open-ended Feedback",
    Allow_respondents_to:
      "Allow respondents to share open-ended feedback after each section",
    Human_Skills: "Human Skills",
    Human_skills_soft_skills:
      "Human skills (soft skills) —written communication, empathy, situational awareness, and critical thinking are essential for developing successful relationships and sound reasoning and decision-making.",
    Evaluate_human_skills: "Evaluate human skills.",
    No_set_up_required_to_assess_written:
      "No set up required to assess written communication, empathy, situational awareness, and critical thinking.",
    Your_Core_Values: "Your Core Values",
    Define_your_organizational:
      "Define your organizational core values as parameters of your Culture Model. Each section will be set up and evaluated based on your values.",
    Quick_Select_or_Add: "Quick Select or Add New Core Values",
    Start_AI_Assisted_Setup: "Start AI-Assisted Setup",
    Proceed_with_Manual_Setup: "Proceed with Manual Setup",
    Pending_Review: "Pending Review",
    Generate: "Generating..",
    Redirect_respondents_to: "Redirect respondents to this URL on completion",
  },
  setup: {
    Setup_Complete: "Setup Completed",
    Thank_you_for_setting: "Thank you for setting up the",
    You_can_now_close_this_window: "You can now close this window",
    _Use_the_same_setup: "- Use the same setup as a previous assessment -",
    valueAndTraits: {
      Setup_your_Values_and_Traits: "Setup your Values & Traits",
      Not_at_all_important: "Not at all important",
      Very_important: "Very important",
      Your_company_core_values:
        "Your company’s core values are unique to you, and they provide you with a competitive advantage. Traits are characteristics that make it possible for people to act and behave according to a set of values.",
      In_the_next_steps_do:
        "In the next steps, do your best to select the values and traits that are most important to your organization.",
      Defining_your_values: "Defining your values",
      Select_and_manage_value:
        "Select and manage a set of values that you would like to have available to evaluate and candidates select from.",
      You_can_add_values: "You can add values to the list too.",
      Values_Library: "Values Library",
      Do_not_include: "Do not include",
      Add_Value: "Add Value",
      Add_a_value: "Add a value",
      Add_a_value_to_the:
        "Add a value to the library of values. Please check to ensure it’s not too similar to an existing value.",
      New_Value_English: "New Value (English)",
      New_Value_Thai: "New Value (Thai)",
      Enter_value_here: "Enter value here",
      Identifying_key_traits: "Identifying key traits",
      Select_and_manage_traits:
        "Select and manage a set of traits that you would like to have available to evaluate and candidates select from.",
      You_can_add_traits: "You can add traits to the list too.",
      Traits_Library: "Traits Library",
      Add_traits: "Add traits",
      Add_a_trait: "Add a trait",
      Add_a_trait_to:
        "Add a trait to the library of traits. Please check to make sure it’s not too similar to an existing trait.",
      New_Trait_English: "New Trait (English)",
      New_Trait_Thai: "New Trait (Thai)",
      Enter_trait_here: "Enter trait here",
    },
    cultureProfile: {
      Setup_your_Culture_Profile: "Setup your Culture Profile",
      The_Happily_Culture_Profile:
        "The Happily Culture Profile is adapted from the Competing Values Framework and used by over 10,000 companies worldwide.",
      Identify_the_culture_type:
        "Identify the culture type that should dominate 6 different aspects of your organization. Do you want more focus on collaboration, control, competition, or creativity?",
    },
    acceptableBehaviors: {
      Setup_your_Acceptable_Behaviors: "Setup your Acceptable Behaviors",
      Identify_areas_of_misalignment:
        "Identify areas of misalignment in acceptable behaviors to eliminate toxic behaviors and align on expectations.",
      In_each_scenario:
        "In each scenario, select the level at which each behavior is acceptable or unacceptable as a baseline for comparison.",
      Review_the_acceptable_behaviors:
        "Review the acceptable behaviors that you would like to evaluate. Add or remove scenarios as needed. We recommend that you have no more than 15 scenarios in total.",
      Workplace_Scenarios: "Workplace Scenarios",
      Add_a_workplace_scenario: "Add a workplace scenario",
      Add_a_scenario_that:
        "Add a scenario that would be evaluated by your organization and the respondants as either acceptable, frowned upon, actively discouraged, reprimanded, or zero-tolerance.",
      New_Scenario_English: "New Scenario (English)",
      New_Scenario_Thai: "New Scenario (Thai)",
      Enter_scenario_here: "Enter scenario here",
    },
  },
  question: {
    valueAndTraits: {
      Values_and_Traits: "Values & Traits",
      Your_values_guide_your:
        "Your values guide your natural tendencies and preferences —we want to act consistently with our values. And traits explain how our values express themselves.",
      In_this_sectio_you_have: "In this section, you have",
      minutes: "minutes",
      to_identify_and_select_your:
        "to identify and select your underlying values and traits.",
      Select_the: "Select the",
      values_that_best_describe: "values most important to you",
      traits_that_best_describe: "traits that best describe you",
      Click_on_a_word_to_add:
        "Click on a word to add or remove it from your selection.",
      SELECT: "SELECT",
      MORE_ITEMS: "MORE ITEMS",
      Rated_very_important_by: "Rated very important by ",
      but_unselected_by: " but unselected by ",
    },
    cultureProfile: {
      Culture_Profile: "Culture Profile",
      The_Happily_Culture_Profile:
        "The Happily Culture Profile is adapted from the Competing Values Framework and used by over 10,000 companies worldwide.",
      Which_culture_type_should:
        "Which culture type should dominate? Do you want more focus on collaboration, control, competition, or creativity?",
      Takes: "Takes",
      minutes: "minutes",
      to_go_evaluate_your_ideal:
        "to go evaluate your ideal organization based on 6 different areas.",
      Assessing_each_aspect_divide:
        "Assessing each aspect, divide 100 points among four choices. Give a higher number of points to the choice that is most similar to the organization you want and less or no points to the alternative that is least similar to what you’re looking for.",
    },
    acceptableBehaviors: {
      Acceptable_Behaviors: "Acceptable Behaviors",
      Evaluating_Acceptable_Behaviors: "Evaluating Acceptable Behaviors",
      In_the_workplace_it_is_not_uncommon:
        "In the workplace, it is not uncommon to come across undesirable behaviors. We may tolerate some more than others.",
      Please_provide_a_rating_for_each:
        "Please provide a rating for each scenario based on your preference and tolerance levels.",
      Acceptable: "Acceptable",
      This_behavior_is_acceptable: "This behavior is acceptable to me.",
      Frowned_Upon: "Frowned Upon",
      This_behavior_is_not_acceptable:
        "This behavior is not acceptable and I am visibly upset when it happens.",
      Actively_Discouraged: "Actively Discouraged",
      This_behavior_is_unacceptable_Discouraged:
        "This behavior is unacceptable. I will go out of my way to discourage it.",
      Reprimanded: "Reprimanded",
      This_behavior_is_unacceptable_Reprimanded:
        "This behavior is unacceptable. There should be repercussions.",
      Zero_Tolerance_Policy: "Zero Tolerance Policy",
      This_behavior_is_irreprehensible:
        "This behavior is irreprehensible, and no second chance should be given.",
      Scenario: "Scenario",
    },
    workStyleIdentifier: {
      Work_Style_Identifier: "Work Style Identifier",
      identify_your_work_style:
        "Identify your work style based on the 3P framework: Product, Process, or People. Better understand how you complete and prioritize activities at work.",
      complete_each_workplace_scenario:
        "Complete each workplace scenario by selecting your primary action, priority, or preference.",
      Based_on_your_natural_tendencies:
        "Based on your natural tendencies, what is your closest response or action to this workplace scenario?",
      SCENARIO: "SCENARIO",
    },
    humanSkills: {
      Showcase_your_communication_ability:
        "Showcase your communication ability, empathy, situational awareness, and critical thinking by answering the following open-ended questions to ",
      The_best_of_your: "the best of your ability.",
      Be_analytical_by_sharing:
        "Be analytical by sharing your thought process, and be specific by providing examples.",
      You_will_have_ten_min:
        "You’ll have 10-min in total to respond to two questions.",
      Write_your_response: "Write your response here...",
    },
  },
  assessmentTest: {
    Hello: "Hello",
    minutes_remaining_defore: "",
    minutes_remaining_after: "minutes remaining",
    Thank_you_for_taking:
      "Thank you for taking the time to complete this assessment with us at",
    Thank_you_for_taking_end: ".",
    A_few_things_you_should: "A few things you should know before you start:",
    Plan_to_take: "Plan to take ",
    minutes_to_complete_this:
      " minutes to complete this assessment uninterrupted.",
    It_is_in_your_best_interest:
      "It is in your best interest to answer truthfully.",
    There_is_no_right: "There is no right or wrong answer.",
    If_you_re_unsure: "If you’re unsure, trust your gut instinct.",
    Thank_you_for_taking_to_complete:
      "Thank you for taking the time to complete the",
    Thank_you_for_taking_to_complete_the_assessment:
      "Thank you for taking the time to complete the assessment",
    section_of_the_assessment: "section of the assessment.",
    You_ve_completed_this_section: "You’ve completed this section",
    You_ve_completed_all_section: "You’ve completed all section",
    You_have_completed_all_sections:
      "You have completed all sections of the assessment. You can now close this window.",
    Once_you_re_ready:
      "Once you’re ready, please continue to the next section.",
    Go_to_the_Next_Section: "Go to the Next Section",
    POINTS_REMAINING: "POINTS REMAINING",
    Optional: "Optional",
    Anyz_feedback_or_thoughts: "Any feedback or thoughts to share about ",
    values_and_traits_q: "values and traits?",
    culture_profile_q: "culture profile?",
    acceptable_behaviors_q: "acceptable behaviors?",
    work_style_identifier_q: "work style identifier?",
    human_skills_q: "human skills?",
    Describe_a_time_when_you:
      "Describe a time when you had to solve a complex problem at work. What was your thought process, and how did you arrive at a solution?",
    Imagine_you_are_working_on:
      "Imagine you are working on a team project with a tight deadline, and you discover a significant flaw in the project’s design. What steps do you take to address the issue, and how do you ensure the project stays on track?",
  },
  assessmentResults: {
    Overall: "Overall",
    Create: "Create",
    Compete: "Compete",
    Control: "Control",
    Collaborate: "Collaborate",
    people_oriented: "people-oriented",
    dynamic_entrepreneurial: "dynamic & entrepreneurial",
    results_oriented: "results-oriented",
    process_oriented: "process-oriented",
    Values: "Values",
    and_: " & ",
    Traits: "Traits",
    Culture_: "Culture",
    Profile_: "Profile",
    Acceptable_: "Acceptable",
    Behaviors_: "Behaviors",
    COPY_LINK: "COPY LINK",
    SEND_RESULTS_TO: "SEND RESULTS TO",
    RE_SEND_RESULTS_TO: "RE-SEND RESULTS TO",
    Completed_on: "Completed on",
    Score_Summary: "Score Summary",
    selection_of_the_candidate_values:
      "This is the selection of the candidate’s values, color-coded by level of importance based on the organization’s values.",
    Our_values_guide_our:
      "Our values guide our natural tendencies and how we work, act, and make decisions.",
    This_is_the_selection:
      "This is the selection of the candidate’s traits, color-coded by level of importance based on the organization’s ideal traits.",
    Our_traits_guide_give:
      "Our traits guide and give us the ability to behave, act, and deliver on our values.",
    The_overall_overlap_between:
      "The overall overlap between the candidate’s ideal work culture and your organization’s work culture.",
    Hover_over_each_dimension: "Hover over each dimension to see",
    how_each_value_is: "how each value is expressed in this section",
    A_comparison_between_what:
      "A comparison between what the candidate and organization feel are acceptable behaviors or not. For any mismatched pair, we recommend having a conversation to clarify expectations.",
    The_candidate_primary_work_style:
      "The candidate’s primary work style suggests natural work tendencies. It does not describe a limitation but explains how they prefer to work and work with others.",
    Work_Style__: "Work Style",
    Composition__: "Composition",
    Product: "Product",
    Doers_and_executors_They:
      "Doers and executors. They’re driven by getting things done and delivering results. However, they often overlook details and people’s sensitivities in their quest to accomplish work.",
    Process: "Process",
    Learners_and_thinkers_They:
      "Learners and thinkers. They are strongest in being analytical and thorough, knowing that success lies in the details. However, they often spend too much time perfecting work rather than getting things done in time or taking care of people.",
    People: "People",
    Relationship_builders_They:
      "Relationship builders. They understand people and know how to get the best out of them. However, they often spend too much time making others feel great rather than follow-up on details or getting work done.",
    Select_a_dimension_to: "Select a dimension to learn more",
    Tap_on_a_row_in_the:
      "Tap on a row in the left panel to see the alignment between the company and respondent's preferences",
    Group_Results: "Group Results",
    ANALYTICS: "ANALYTICS",
    Legend: "Chart Legend",
    The_overlap_between_the__workplace:
      "The overlap between the candidate’s ideal type of workplace and your organization’s type of workplace.",
    A_human_skills_profile_for:
      "A human skills profile for each candidate based on written communication, empathy, situational awareness, and critical thinking",
    There_are_no_values_assigned:
      "There are no values assigned to this level of importance.",
    Progress: "Progress",
    Assessment_Results_for: "Assessment Results for",
    Human_: "Human",
    Skills_: "Skills",
    The_candidate_human_skills_are:
      "The candidate’s human skills are evaluated based on how they responded to two open-ended questions. An evaluation of written communication, empathy, situational awareness, and critical thinking is provided —also how core values are expressed (core values ratings do not factor into the overall score but are displayed for alignment purposes).",
    Completion_Time: "Completion Time",
  },
  coreValuesAnalyzer: {
    My_Core_Values: "My Core Values",
    Audit: "Audit",
    Skills: "Skills",
    Survey_Question: "Survey Questions",
    Microlearning: "Microlearning",
    Clarity: "Clarity",
    Practical: "Practical",
    Best_Practice: "Best Practice",
    Inspiring: "Inspiring",
    Audit_Summary: "Audit Summary",
    Purpose_driven: "Purpose-driven",
    Alternative_Options: "Alternative Options",
    Auditing_you_values: "Auditing your values…",
    Overall: "Overall",
    Simple_Language: "Simple Language",
    Potential_Misunderstanding: "Potential Misunderstanding",
    Consistency: "Consistency",
    Actionable: "Actionable",
    Easy_To_Reinforce: "Easy To Reinforce",
    Measureable: "Measureable",
    Not_Permission_To_Play: "Not Permission To Play",
    Sense_Of_Pride: "Sense Of Pride",
    Positive_Impact: "Positive Impact",
    Analyze_everything_you:
      "Analyze everything you need to know about your core values, and get recommendations on skills to develop, questions to ask, and information to share.",
    Enter_from_1_to: "Enter core values (8 max)",
    Analyze: "Analyze",
    Analyzing: "Analyzing",
    What_you_get: "What you get",
    Audit_your_core_values: "Audit your core values",
    Analyze_how_well_your:
      "Analyze how well your core values score on clarity, being practical, following best practice, and being inspiring.",
    Ratings_recommendations_and:
      "Ratings, recommendations, and alternate core values are provided to help you develop your core values.",
    Get_a_Skills_Development: "Get a Skills Development Plan",
    Get_a_skill_evelopment:
      "Get a skill development framework that helps your employees can effectively express and deliver on your values.",
    Leaders_an_encourage_and:
      "Leaders can encourage and reward employees who embody these values, and provide opportunities for employees to practice and develop these skills.",
    Generate_Survey_Questions: "Generate Survey Questions",
    Help_your_employees_regularly:
      "Help your employees regularly reflect and share feedback based on your core values. Add these questions to your pulse survey or feedback tool of choice.",
    Learning_Content_to_Support: "Learning Content to Support Your Values",
    // --
    Get_micro_learning_content:
      "Get micro-learning content snippets to share through various channels such as emails, company intranet, social media, or a learning management system.",
    Short_and_easily_digestible:
      "Short and easily digestible, they allow employees to reflect on and improve upon the core values quickly and conveniently.",
    Skills_and_Development_Guidance: "Skills & Development Guidance",
    In_order_for_your:
      "In order for your employees to express and stay true to your core values they must possess or and continuously develop the following skills:",
    By_veloping_and_supporting:
      "By developing and supporting these skills, employees can effectively express and deliver on the core values of",
    Leaders_can_encourage_and:
      "Leaders can encourage and reward employees who embody these values, and provide opportunities for employees to practice and develop these skills.",
    Questions: "Questions",
    Regularly_get_your_employees:
      "Regularly get your employees to reflect and share feedback based on the following questions. Add these questions to your pulse survey or feedback tool of choice:",
    These_micro_learning_content:
      "These micro-learning content snippets can be shared through various channels such as emails, company intranet, social media, or a learning management system. They are intended to be short and easily digestible, allowing employees to reflect on and improve upon the core values in a quick and convenient manner.",
    Generate_more_content: "Generate more content",
    Create_an_account_to:
      "Create an account to unlock skills, survey questions, and microlearning",
    Download_This_Report: "Download This Report",
    Download_to_save: "Save this core values audit and analysis as a PDF file",
    Download: "Download",
    Download_pdf_title:
      "A Core Values Report: An Audit, Skill & Development Guidance, Survey Questions, and Microlearning",
    Download_pdf_success: "Preparing to download the report...",
  },
  headerTable: {
    NAME: "NAME",
    CANDIDATES: "CANDIDATES",
    PROGRESS: "PROGRESS",
    LAST_ACTIVITY: "LAST ACTIVITY",
    DATE_CREATED: "DATE CREATED",
    ACTIONS: "ACTIONS",
    JOINED: "JOINED",
    SCORE: "SCORE",
    STATUS: "STATUS",
    YOUR_RATING: "YOUR RATING",
  },
  popup: {
    optional: {
      Yes: "Yes",
      Yes_chi: "Yes",
      Confirm: "Confirm",
      Cancel: "Cancel",
      Continue: "Continue",
      Go_to_Next_Section: "Go to Next Section",
    },
    question: {
      question_box_1: {
        title: "Send results to ",
        question_1: "We will send an email, on your behalf, to ",
        question_2:
          " with a private link to access their results. Are you sure you would like to proceed?",
      },
      question_box_2: {
        title: "Warning",
        question: "Are you sure you want to delete this assessment?",
      },
      question_box_3: {
        title: "Warning",
        question: "Are you sure you want to delete this candidate?",
      },
      question_box_4: {
        title: "Set up",
        question: "Do you want to edit your current set up process?",
      },
      question_box_5: {
        title: "Warning",
        question:
          "Are you sure you want to remove this scenario from being evaluated for this assessment?",
      },
    },
    alert: {
      alert_box_1: {
        title: "No time remaining",
        description:
          "The allocated time for this section has run out. Please continue to the next step.",
      },
      alert_box_2: {
        title: "Session Time Out",
        description:
          "Please proceed to the next section of the assessment to complete your submission. Otherwise, you are at risk of losing your responses and may have to re-take the assessment.",
      },
      question_box_5: {
        title: "Warning",
        question:
          "Are you sure you want to remove this scenario from being evaluated for this assessment?",
      },
    },
    message: {
      message_1: "You have sent assessment results",
      message_2: "An invitation email has been resent to ",
      message_3_1: "The results of ",
      message_3_2: " assessment has been successfully cleared",
      message_4: "Data no found!",
      message_5e: "This image must be a JPG or PNG!",
      message_6e: "This image is bigger than 2MB!",
      message_7e: "Invalid email or OTP. Please check again.",
      message_8: "Copy to clipboard",
      message_9e: "0 remaining this month",
      message_10e: "Maximum emails to send is ",
      message_11: "Emails sent",
      message_12: "Data no found!",
      message_13_1: "Email has been sent to ",
      message_13_2: "",
      message_14w: "Request: 1 section!",
      message_15w: "Please complete your setup!",
      message_16e: "Oops, something went wrong!",
      message_17: "Referral link copied to your clipboard.",
      message_18: "Saved, user details have been updated!",
      message_19: "The link to this report has been copied to your clipboard.",
      message_20w: "You can only have a maximum of 100 recipients.",
    },
  },
  datetime: {
    months: {
      January: "January",
      February: "February",
      March: "March",
      April: "April",
      May: "May",
      June: "June",
      July: "July",
      August: "August",
      September: "September",
      October: "October",
      November: "November",
      December: "December",
    },
  },
  coupon: {
    error_messages: {
      message_1: "Coupon code is required.",
      message_2: "Coupon code was not found.",
      message_3: "The coupon code has already expired.",
      message_4: "Coupon usage limit has been reached.",
      message_5: "Coupon already used.",
    },
  },
};

export default en;
