
import { defineComponent } from "vue";
import { ElMessageBox } from "element-plus";
import helpers from "@/helpers/global";
import MyAssessmentsTable from "@/components/Table/MyAssessments.vue";
// let loadingEl: any;

export default defineComponent({
  name: "Assessments",
  tableData: [],
  components: {
    MyAssessmentsTable,
  },
  async mounted() {
    if (!localStorage.getItem("userData")) {
      // this.$router.replace("/");
      return;
    }

    // auto create sample data for resolve issue "empty table" (client side process)
    /*
    await this.$store.dispatch("sampleData/createAssessmentTemplate", null, {
      root: true,
    });
    //*/

    // Fix route data from sample data
    // await this.$store.dispatch("sampleData/fixRotueDataAll", null, {
    //   root: true,
    // });

    this.loadTables();
    // this.tableData = [
    //   {
    //     name: "Culture Fit for apr 2022 Batch",
    //     id: "11111111",
    //     candidates: 10,
    //     progress: 2,
    //     lastActivity: "a day ago",
    //     dateCreated: "23 day ago",
    //   },
    //   {
    //     name: "Culture Fit for mar 2022 Batch",
    //     id: "111111122212",
    //     candidates: 7,
    //     progress: 4,
    //     lastActivity: "19 day ago",
    //     dateCreated: "2 month ago",
    //   },
    //   {
    //     name: "Culture Fit for feb 2022 Batch",
    //     id: "111111122212",
    //     candidates: 7,
    //     progress: 4,
    //     lastActivity: "1 month ago",
    //     dateCreated: "3 month ago",
    //   },
    //   {
    //     name: "Culture Fit for Jan 2022 Batch",
    //     id: "111111122212",
    //     candidates: 18,
    //     progress: 1,
    //     lastActivity: "3 month ago",
    //     dateCreated: "4 month ago",
    //   },
    //   {
    //     name: "Cat are ant, cat are dog too. So dog is ant?",
    //     id: "111111122212",
    //     candidates: 100,
    //     progress: 95,
    //     lastActivity: "28 day ago",
    //     dateCreated: "28 day ago",
    //   },
    // ];
  },
  data() {
    return {
      // tableData: [],
    };
  },
  // watch: {
  //   loading(value) {
  //     if (value) {
  //       loadingEl = ElLoading.service({
  //         lock: true,
  //         text: "Login..",
  //         background: "#ffffff90",
  //       });
  //     } else {
  //       if (loadingEl) {
  //         loadingEl.close();
  //       }
  //     }
  //   },
  // },
  computed: {
    tableData() {
      return this.$store.getters["assessmentTemplate/items"];
    },
    loading() {
      return this.$store.getters["assessmentTemplate/loading"];
    },
  },
  methods: {
    barWidht(progress: number, candidates: number) {
      const pergressPercentage = (progress / candidates) * 100;
      return `width: ${pergressPercentage.toFixed(1)}%`;
    },
    fontColor(progress: number, candidates: number) {
      const pergressPercentage = (progress / candidates) * 100;
      if (+pergressPercentage.toFixed(1) > 50) {
        return "fc-FFFFFF";
      }
      return "text-shadow";
    },
    getLocaleDate(time: string) {
      return helpers.getLocaleDate(time);
    },
    timeAgo(time: string) {
      return helpers.timeAgo(time);
    },
    loadTables() {
      this.$store.dispatch(
        "assessmentTemplate/getList",
        { page: 1 },
        { root: true }
      );
    },
    async handleCommand(command: string, row: any) {
      const routeId = row.routeId;
      switch (command) {
        case "edit":
          this.$router.replace(`/assessment/edit/${row.routeId}`);
          break;
        case "clone":
          await this.$store.dispatch(
            "assessmentTemplate/clone",
            {
              routeId: routeId,
            },
            { root: true }
          );
          this.loadTables();
          break;
        case "delete":
          ElMessageBox.confirm(
            (this as any).$t("popup.question.question_box_2.title"),
            (this as any).$t("popup.question.question_box_2.question"),
            {
              confirmButtonText: (this as any).$t("popup.optional.Yes_chi"),
              cancelButtonText: (this as any).$t("popup.optional.Cancel"),
              type: "warning",
            }
          )
            .then(async () => {
              await this.$store.dispatch("assessmentTemplate/softDelete", row, {
                root: true,
              });
              this.loadTables();
            })
            .catch((error) => {
              if (error == "cancel") return;
              console.error(error);
            });
      }
    },
    async create() {
      const callback = () => {
        this.$router.push({
          name: "AssessmentsCreate",
          params: { step: "name" },
        });
      };
      await this.$store.dispatch(
        "assessmentTemplate/cleanup",
        { callback },
        {
          root: true,
        }
      );
    },
  },
});
